import React, { useEffect, useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import ReusablePopup from '../NPSportal/ReusablePopup';
import EditableGrid from '../Quickship/EditableGrid';
import Loading from '../Shared/Loading';
const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const FreightScans = () => {
  const axiosJWT = useAxiosJWT();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [gridColumns, setGridColumns] = useState();
  const [tableInfo, setTableInfo] = useState();
  const [popup, setPopup] = useState(false);

  function getGridColumns(maxArrayLength) {
    axiosJWT
      .post(`${apiAddress}/itemTable/getGridColumns`, { dataTitle: 'scans' })
      .then((response) => {
        let columns = response.data;

        // Remove existing arrayColumn and replace with dynamically created ones
        columns = columns.filter((col) => col.field !== 'images');

        if (maxArrayLength > 0) {
          const additionalColumns = Array.from({ length: maxArrayLength }, (_, i) => ({
            field: `image_${i + 1}`,
            headerName: `Image ${i + 1}`,
            editable: false,
            cellDataType: 'image',
          }));

          columns = columns.concat(additionalColumns);
        }
        setGridColumns(columns);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getTableInfo() {
    axiosJWT
      .post(`${apiAddress}/itemTable/getGridTableInfo`, { dataTitle: 'scans' })
      .then((response) => {
        setTableInfo(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getData() {
    setLoading(true);
    axiosJWT
      .get(`${apiAddress}/itemTable/getScans`)
      .then((response) => {
        const rawData = response.data;

        // Find max length of arrayColumn across all rows
        const maxArrayLength = Math.max(
          ...rawData.map((row) => (Array.isArray(row.images) ? row.images.length : 0)),
          0
        );

        // Transform the data to spread array items into separate fields
        const transformedData = rawData.map((row) => {
          if (Array.isArray(row.images)) {
            row.images.forEach((item, index) => {
              row[`image_${index + 1}`] = item;
            });
          }
          return row;
        });

        setData(transformedData);
        getGridColumns(maxArrayLength); // Pass max array length to generate columns
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  const image = ({ row, column }) => {
    return (
      <div
        role='button'
        aria-label='action'
        tabIndex={0}
        onClick={() => {
          setPopup({
            title: `${row.pro_number} - ${column.headerName}`,
            innerComponent: (
              <img
                alt='scan image'
                style={{ width: '100%', height: 'auto' }}
                src={`${row[column.field]}`}
              ></img>
            ),
          });
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setPopup({
              title: `${row.pro_number} - ${column.headerName}`,

              innerComponent: (
                <img
                  alt='scan image'
                  style={{ width: '100%', height: 'auto' }}
                  src={`${row[column.field]}`}
                ></img>
              ),
            });
          }
        }}
      >
        {row[column.field]}
      </div>
    );
  };

  const stringList = ({ row }) => {
    return (
      <>
        <div
          role='button'
          aria-label='action'
          tabIndex={0}
          onClick={() => {
            setPopup({
              title: `${row.pro_number} - Items`,
              innerComponent: (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  {row.items?.split(', ')?.map((i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          padding: '0.5rem',
                          borderBottom: '1px solid var(--lightgrey)',
                        }}
                        className='basic-flex'
                      >
                        <div>{i.substring(0, i.indexOf('(')).trim()}</div>
                        <div> {i.substring(i.indexOf('(')).trim()}</div>
                      </div>
                    );
                  })}
                </div>
              ),
            });
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setPopup({
                title: `${row.pro_number} - Items`,
                innerComponent: row.items?.split(', '),
              });
            }
          }}
        >
          {row?.items}
        </div>
      </>
    );
  };

  useEffect(() => {
    getData();
    getTableInfo();
  }, []);

  return (
    <>
      {!loading && data && gridColumns ?
        <>
          <div>
            <EditableGrid
              data={data}
              columns={gridColumns}
              title={'Freight Scans'}
              tableInfo={tableInfo}
              imageComponent={image}
              listComponent={stringList}
            />
          </div>
        </>
      : loading ?
        <Loading />
      : ''}

      {popup && (
        <ReusablePopup
          open={popup}
          title={popup.title}
          setOpen={setPopup}
          innerComponent={popup.innerComponent}
        />
      )}
    </>
  );
};

export default FreightScans;
