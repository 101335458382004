import { AdvancedImage } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../CSS/Patents.css';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../../Components/Shared/FormatImageLink';

const Patents = () => {
  const { generalImages } = useGeneralImages();
  const { decodeAndFormat, optimizeCloudinaryUrl } = FormatImageLink();
  const banner = generalImages.find((i) => i.publicId === 'Patents_rimr4f')?.imageLink;

  useEffect(() => {
    document.title = `Patents - National Public Seating`;
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();

  const [patents, setPatents] = useState();
  const [patentPending, setPatentPending] = useState();
  const axiosJWT = useAxiosJWT();
  function getData() {
    axiosJWT
      .get('content/getPatents')
      .then((response) => {
        setPatents(
          response.data.patents.filter((i) => {
            return i?.type?.toLowerCase() === 'patent';
          })
        );
        setPatentPending(
          response.data.patents.filter((i) => {
            return i?.type?.toLowerCase() === 'patent pending';
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);
  const bannerText =
    'NPS Public Furniture Corp. is marking the products identified on this website pursuant to 35 U.S.C. §287(a) by associating each identified product with one or more patents that cover that product.';

  return (
    <div className='patent-page'>
      <div className='patents-banner'>
        <div className='banner'>
          <img
            src={optimizeCloudinaryUrl(banner)}
            alt='NPS Patents Banner'
          ></img>
          <div className='banner-text'>{bannerText}</div>
        </div>
      </div>
      <h2>Patent</h2>
      <div className='underline'></div>
      <div className='patents inner-padding'>
        {patents?.map((i, index) => {
          return (
            <div
              role='button'
              aria-label={i.title}
              tabIndex={0}
              key={index}
              className='patent-tile'
              onClick={() => {
                navigate(i.link);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  navigate(i.link);
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              <div className='patent-img'>
                <AdvancedImage
                  cldImg={decodeAndFormat(i.img)?.resize(scale().width(200))}
                  alt={`${i.title}`}
                />
              </div>
              <div>
                <h1>Patented</h1>
                <h2 className={i.title.length > 33 ? 'smaller' : ''}>{i.title}</h2>
                <h3>Patent ID</h3>
                <h4>{i.patentNum}</h4>
              </div>
            </div>
          );
        })}
      </div>
      <h2 style={{ marginTop: '4rem' }}>Patent Pending</h2>
      <div className='underline'></div>
      <div className='patents inner-padding'>
        {patentPending?.map((i, index) => {
          return (
            <div
              role='button'
              aria-label={i.title}
              tabIndex={0}
              key={index}
              className='patent-tile pending'
              onClick={() => {
                navigate(i.link);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  navigate(i.link);
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              <div className='patent-img'>
                <AdvancedImage
                  cldImg={decodeAndFormat(i.img)?.resize(scale().width(200))}
                  alt={`${i.title}`}
                />
              </div>
              <div>
                <h1>Patent Pending</h1>
                <h2>{i.title}</h2>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Patents;

