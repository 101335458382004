import { useEffect, useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import { useParams } from 'react-router-dom';
import EditableGrid from './EditableGrid';
import Loading from '../Shared/Loading';
import GridTopComponent from './GridTopComponent';
const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const UseEditableGrid = () => {
  const { title } = useParams();
  const [data, setData] = useState();
  const [gridColumns, setGridColumns] = useState();
  const [tableInfo, setTableInfo] = useState();
  const [loading, setLoading] = useState();
  const axiosJWT = useAxiosJWT();

  function getGridColumns(maxArrayLength) {
    axiosJWT
      .post(`${apiAddress}/itemTable/getGridColumns`, { dataTitle: title })
      .then((response) => {
        let columns = response.data;

        // Remove existing arrayColumn and replace with dynamically created ones
        columns = columns.filter((col) => col.field !== 'images');

        if (maxArrayLength > 0) {
          const additionalColumns = Array.from({ length: maxArrayLength }, (_, i) => ({
            field: `image_${i + 1}`,
            headerName: `Image ${i + 1}`,
            editable: false,
          }));

          columns = columns.concat(additionalColumns);
        }
        setGridColumns(columns);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getTableInfo() {
    axiosJWT
      .post(`${apiAddress}/itemTable/getGridTableInfo`, { dataTitle: title })
      .then((response) => {
        setTableInfo(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getData() {
    setLoading(true);
    axiosJWT
      .get(`${apiAddress}/itemTable/get${title}`)
      .then((response) => {
        const rawData = response.data;

        // Find max length of arrayColumn across all rows
        const maxArrayLength = Math.max(
          ...rawData.map((row) => (Array.isArray(row.images) ? row.images.length : 0)),
          0
        );

        // Transform the data to spread array items into separate fields
        const transformedData = rawData.map((row) => {
          if (Array.isArray(row.images)) {
            row.images.forEach((item, index) => {
              row[`image_${index + 1}`] = item;
            });
          }
          return row;
        });

        setData(transformedData);
        getGridColumns(maxArrayLength); // Pass max array length to generate columns
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  function postData(info) {
    setLoading(true);
    axiosJWT
      .post(`${apiAddress}/itemTable/get${title}`, { info: info })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  function saveView(data, truncate) {
    setLoading(true);

    if (data?.length) {
      axiosJWT
        .post(`${apiAddress}/itemTable/saveChangeData`, {
          data: data,
          columns: gridColumns,
          tableInfo: tableInfo,
          preventTruncate: truncate,
        })
        .then(() => {
          getData();
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  }

  useEffect(() => {
    // getGridColumns();
    getData();
    getTableInfo();
  }, []);

  return !loading && data ?
      <>
        <GridTopComponent
          title={title}
          postData={postData}
        />
        <EditableGrid
          columns={gridColumns}
          data={data}
          title={title}
          tableInfo={tableInfo}
          saveData={saveView}
        />
      </>
    : <Loading />;
};

export default UseEditableGrid;
