import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../CSS/MediaNavButtons.css';
import '../../CSS/MobileApp/M_MediaNavButtons.css';
import { IoIosArrowForward } from 'react-icons/io';
import { useSelector } from 'react-redux';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../Shared/FormatImageLink';

const MediaNavButtons = ({ current }) => {
  const isMobile = useSelector((state) => state.isMobile.value.isMobile);
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const media = generalImages.find((i) => i.publicId === 'Media_ovolmw')?.imageLink;
  const catalog = generalImages.find((i) => i.publicId === 'MC_Digital_Catalogs_t70spe')?.imageLink;
  const videosHover = generalImages.find(
    (i) => i.publicId === 'MC_Promo_Videos_P_zdktor'
  )?.imageLink;
  const mediaHover = generalImages.find(
    (i) => i.publicId === 'MC_Media_Downloads_P_yw7jv9'
  )?.imageLink;
  const brochuresHover = generalImages.find(
    (i) => i.publicId === 'MC_Brochures_P_a5j4yz'
  )?.imageLink;
  const catalogHover = generalImages.find(
    (i) => i.publicId === 'MC_Digital_Catalogs_P_p3eeqc'
  )?.imageLink;
  const videos = generalImages.find((i) => i.publicId === 'Videos_cgwtzf')?.imageLink;
  const brochures = generalImages.find((i) => i.publicId === 'Brochures_ihbs9w')?.imageLink;

  let mediaButtons = [
    {
      title: 'Media Downloads',
      link: '/media',
      img: media,
      imgHover: mediaHover,
    },
    {
      title: 'Digital Catalogs',
      link: '/catalog',
      img: catalog,
      imgHover: catalogHover,
    },
    {
      title: 'Brochures',
      link: '/brochures',
      img: brochures,
      imgHover: brochuresHover,
    },
    {
      title: 'Videos',
      link: '/videos',
      img: videos,
      imgHover: videosHover,
    },
  ];
  if (!isMobile) {
    mediaButtons = mediaButtons.filter((i) => {
      return i.title !== current;
    });
  }

  const [hover, setHover] = useState();

  const navigate = useNavigate();
  return (
    <div className='mediaNavButtons inner-padding'>
      {mediaButtons.map((i) => {
        return (
          <button
            className={isMobile && i.title === current ? 'current-media-button' : ''}
            onClick={() => {
              navigate(`${i.link}`);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate(`${i.link}`);
              }
            }}
            onMouseEnter={() => {
              setHover(i.title);
            }}
            onMouseLeave={() => {
              setHover();
            }}
            key={i.link}
          >
            <div
              className='media-nav-button'
              style={{ alignItems: 'center', display: 'flex' }}
            >
              <img
                src={
                  hover === i.title || (isMobile && i.title !== current) ?
                    optimizeCloudinaryUrl(i.imgHover)
                  : optimizeCloudinaryUrl(i.img)
                }
                alt={`Check out the ${i.title}`}
              />
              {i.title}
            </div>
            {!isMobile && <IoIosArrowForward />}
          </button>
        );
      })}
    </div>
  );
};

export default MediaNavButtons;
