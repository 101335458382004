import React from 'react';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from './FormatImageLink';

const LoadingIcon = () => {
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const icon = generalImages.find((i) => i.publicId === 'Loading_biviek')?.imageLink;

  return (
    <img
      src={optimizeCloudinaryUrl(icon)}
      style={{
        height: '10rem',
      }}
      alt='Loading Icon'
    />
  );
};

export default LoadingIcon;
