import '../../CSS/DealerCenter.css';
import FormatImageLink from '../Shared/FormatImageLink';
const DealerCube = ({ data }) => {
  const { optimizeCloudinaryUrl } = FormatImageLink();
  return (
    <div className='dealerCenterCube'>
      <img
        src={optimizeCloudinaryUrl(data.image)}
        alt={data.title}
      />
      <h2>{data.title}</h2>
      {data?.sublinks?.map((i, index) => {
        return (
          <button
            key={index}
            onClick={() => {
              window.open(`/${i.link}`);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                window.open(`/${i.link}`);
              }
            }}
            className='dealer-portal-button'
          >
            {i.title}
          </button>
        );
      })}
    </div>
  );
};

export default DealerCube;
