import Axios from 'axios';
import { useEffect, useState } from 'react';
import '../../CSS/Returns.css';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../../Components/Shared/FormatImageLink';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const Returns = () => {
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const banner = generalImages.find((i) => i.publicId === 'Returns_wjltiq')?.imageLink;
  const [terms, setTerms] = useState();

  useEffect(() => {
    document.title = `Returns - National Public Seating`;
    window.scrollTo(0, 0);
  });

  function getTerms() {
    Axios.get(`${apiAddress}/content/getTerms`)
      .then((response) => {
        setTerms(response.data.filter((i) => i.title === 'RETURNS' || i.title === 'CANCELLATIONS'));
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <div className='returns'>
      <div className='banner'>
        <img
          src={optimizeCloudinaryUrl(banner)}
          alt='Returns and Cancellations Banner'
        ></img>
      </div>
      <div className=' inner-padding'></div>
      {terms?.map((i) => (
        <>
          <h3>{i.title}</h3>
          <div className='underline'></div>
          <div className='returnDiv'>
            <img
              src={optimizeCloudinaryUrl(i.image_link)}
              alt={i.title}
            ></img>
            <div className='returnText'>{i.details}</div>
          </div>
        </>
      ))}
    </div>
  );
};

export default Returns;
