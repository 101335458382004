import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MediaCenterCube from '../../Components/Media/MediaCenterCube';
import TemplateTilePage from '../../Components/Shared/TemplateTilePage';
import '../../CSS/MediaCenter.css';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../../Components/Shared/FormatImageLink';

const MediaCenter = () => {
  const mobileScreen = useSelector((state) => state.isMobile.value);
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const mediaBanner =
    mobileScreen.isMobile ?
      generalImages.find((i) => i.publicId === 'media_center_banner')?.imageLink
    : generalImages.find((i) => i.publicId === 'Banner_Media_Center_bxofyt')?.imageLink;

  useEffect(() => {
    document.title = 'Media - National Public Seating';
  }, []);
  const navigate = useNavigate();
  function clickHandler(i) {
    navigate(`/${i.link}`);
  }
  const list = [
    {
      title: 'Media Downloads',
      link: 'media',
    },
    {
      title: 'Digital Catalog',
      link: 'catalog',
    },
    {
      title: 'Brochures',
      link: 'brochures',
    },
    {
      title: 'Promo Videos',
      link: 'videos',
    },
  ];
  return (
    <div className='mediaCenter'>
      <TemplateTilePage
        banner={
          <img
            src={optimizeCloudinaryUrl(mediaBanner)}
            alt='Media Center Banner'
          ></img>
        }
        across={4}
        elements={list.map((i, index) => {
          return (
            <MediaCenterCube
              key={index}
              data={i}
              clickHandler={clickHandler}
            />
          );
        })}
      />
    </div>
  );
};

export default MediaCenter;
