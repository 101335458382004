import { AdvancedImage } from '@cloudinary/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStageInfo } from '../../context/StageProvider';
import BasicButton from '../NPSportal/basicButton';
import LoadingIcon from '../Shared/LoadingIcon';
import AddDollies from './AddDollies';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../Shared/FormatImageLink';

function AddAccessories() {
  const { decodeAndFormat, optimizeCloudinaryUrl } = FormatImageLink();
  const { generalImages } = useGeneralImages();
  const {
    stageBuild,
    buildProducts,
    accessoryPage,
    setAccessoryPage,
    setCartProducts,
    surface,
    setSurface,
    surfaceOptions,
    steps,
    skirtingData,
    setBuildStep,
    selectedColor,
    setSelectedColor,
    selectedStyle,
    setSelectedStyle,
    setWarning,
  } = useStageInfo();

  const [stepsWarning, setStepsWarning] = useState(false);

  function handleSetPage(direction) {
    if (direction === 'next') {
      if (accessoryPage === 4) {
        setBuildStep(3);
      }
      //if the build only has risers or lowest level stages, skip the select steps page
      if (
        accessoryPage === 0 &&
        (!stageBuild.some(
          (item) => item.productList[0].productInfo.Series === 'Fixed Height Stages'
        ) ||
          !stageBuild.some((item) => item.productList[0]?.configOptions.Height.value !== '8'))
      ) {
        setAccessoryPage(accessoryPage + 2);
      }
      //if no steps were added, show warning
      else if (accessoryPage === 1 && !steps.length) {
        setStepsWarning(true);
      }
      //if the build only has risers, skip the dollies page
      else if (
        accessoryPage === 2 &&
        !stageBuild.some((item) => item.productList[0].productInfo.Series === 'Fixed Height Stages')
      ) {
        setBuildStep(3);
      } else {
        setAccessoryPage(accessoryPage + 1);
      }
    } else if (direction === 'prev') {
      if (accessoryPage > 0) {
        //if the build only has risers or lowest level stages, skip the select steps page
        if (
          accessoryPage === 2 &&
          (!stageBuild.some(
            (item) => item.productList[0].productInfo.Series === 'Fixed Height Stages'
          ) ||
            !stageBuild.some((item) => item.productList[0]?.configOptions.Height.value !== '8'))
        ) {
          setAccessoryPage(accessoryPage - 2);
        } else {
          setAccessoryPage(accessoryPage - 1);
        }
      } else {
        setWarning(true);
      }
    }
  }

  function getRender() {
    if (stepsWarning) {
      return (
        <div className='build-warning'>
          <h4>
            You have not selected any steps. We recommend adding at least one set of steps to make
            the stage accessible.
          </h4>
          <span className='basic-flex'>
            <BasicButton
              onClick={() => {
                setStepsWarning(false);
                setAccessoryPage(2);
              }}
              text={'Continue'}
              type={'basic-white-button'}
            />
            <BasicButton
              onClick={() => {
                setStepsWarning(false);
                setAccessoryPage(1);
              }}
              text={'Add Steps'}
              type={'white-bordered-button'}
            />
          </span>
        </div>
      );
    }
    switch (accessoryPage) {
      case 0:
        return (
          <div className='accessory-config'>
            <div className='stage-category stage-category-open'>Stage Surface</div>
            <div className='accessory-config-options'>
              {surfaceOptions.map((srfc, index) => (
                <div
                  role='button'
                  aria-label={srfc.selectionName}
                  tabIndex={0}
                  key={index}
                  className={`option-cube ${
                    srfc.selectionName === surface ? 'option-cube-clicked' : ''
                  }`}
                  onClick={() => setSurface(srfc.selectionName)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setSurface(srfc.selectionName);
                    }
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <AdvancedImage
                    cldImg={decodeAndFormat(srfc.imageLink)}
                    style={{
                      cursor: 'pointer',
                      opacity: '1',
                    }}
                    alt={srfc.selectionName}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      case 1:
        return (
          <>
            <h2>Steps</h2>
            <div>
              <h4>Select Sides to add Steps</h4>
              <span>
                The step heights will be adjusted to match the side of the stage selected.
              </span>
            </div>
            <img
              width='300px'
              height='315px'
              src={optimizeCloudinaryUrl(
                generalImages.find((i) => i.publicId === 'STP24_vdkeam')?.imageLink
              )}
              alt='steps'
            />
            <span className='accessory-product-link'>
              Learn more about this product by
              <Link
                to={{
                  pathname: `/products/Stage Steps`,
                }}
                target='_blank'
                rel='noopener noreferrer'
              >
                clicking here
              </Link>
              .
            </span>
          </>
        );
      case 2:
        return (
          <>
            <h2>Guardrails</h2>
            <div>
              <h4>Select Sides to Add/Remove Guardrails</h4>
              <span>
                For safety purposes, it is recommended that any stage exceeding a height of 8 inches
                and any riser exceeding a height of 16 inches be equipped with guardrails.
                Accordingly, guardrails have been included by default for such stages. Users may opt
                to remove these guardrails; however, they do so at their own risk and assume full
                responsibility for any potential safety hazards.
              </span>
            </div>
            <img
              width='180px'
              height='180px'
              src={optimizeCloudinaryUrl(
                generalImages.find((i) => i.publicId === 'GRR24S_eijxem')?.imageLink
              )}
              alt='guardrail'
              className='guardrail-image'
            />
            <span className='accessory-product-link'>
              Learn more about this product by
              <Link
                to={{
                  pathname: `/categories/Guardrails`,
                }}
                target='_blank'
                rel='noopener noreferrer'
              >
                clicking here
              </Link>
              .
            </span>
          </>
        );
      case 3:
        return (
          <>
            <h2>Skirting</h2>
            <h4>Select Sides to Add/Remove Skirting</h4>
            <div id='skirting-selections'>
              {skirtingData ?
                <>
                  <div className='accessory-config'>
                    <div className='stage-category stage-category-open'>Style</div>
                    <div className='accessory-config-options skirting-options'>
                      {skirtingData?.configOptions.config.Style.Style.values.map((style, index) => (
                        <div
                          role='button'
                          aria-label={style.selectionName}
                          tabIndex={0}
                          key={index}
                          className={`option-cube ${
                            style.selectionName === selectedStyle ? 'option-cube-clicked' : ''
                          }`}
                          onClick={() => setSelectedStyle(style.selectionName)}
                          style={{ cursor: 'pointer' }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              setSelectedStyle(style.selectionName);
                            }
                          }}
                        >
                          <AdvancedImage
                            cldImg={decodeAndFormat(style.imageLink)}
                            style={{
                              cursor: 'pointer',
                              opacity: '1',
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className='accessory-config'>
                    <div className='stage-category stage-category-open'>Color</div>
                    <div className='accessory-config-options skirting-options'>
                      {skirtingData?.configOptions.finishes.Color.Color.values.map(
                        (color, index) => (
                          <div
                            role='button'
                            aria-label={color.selectionName}
                            tabIndex={0}
                            key={index}
                            className={`option-cube ${
                              color.selectionName === selectedColor ? 'option-cube-clicked' : ''
                            }`}
                            onClick={() => setSelectedColor(color.selectionName)}
                            style={{ cursor: 'pointer' }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                setSelectedColor(color.selectionName);
                              }
                            }}
                          >
                            <AdvancedImage
                              cldImg={decodeAndFormat(color.imageLink)}
                              style={{
                                cursor: 'pointer',
                                opacity: '1',
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </>
              : <LoadingIcon />}
            </div>
            <span className='accessory-product-link'>
              Learn more about this product by
              <Link
                to={{
                  pathname: `/products/Skirting`,
                }}
                target='_blank'
                rel='noopener noreferrer'
              >
                clicking here
              </Link>
              .
            </span>
          </>
        );
      case 4:
        return <AddDollies />;
    }
  }

  useEffect(() => {
    const finishedProducts = buildProducts.map((product) => {
      return {
        ...product,
        modelNum: product.productList.find((p) => p.configOptions.Surface.selectionName === surface)
          .modelNum,
        product: product.productList.find((p) => p.configOptions.Surface.selectionName === surface),
      };
    });
    setCartProducts(finishedProducts);
  }, [surface]);

  return (
    <div className='add-accessories'>
      {getRender()}
      {accessoryPage > 0 && (
        <div className='stage-references accessory-references'>
          <span>COLOR REFERENCES</span>
          <div className='height-ref-list color-ref-list'>
            <div
              className='height-ref-tile'
              style={{ backgroundColor: '#73D262' }}
            ></div>
            <span className={accessoryPage === 1 ? 'bold' : ''}>Steps</span>
            <div
              className='height-ref-tile'
              style={{ backgroundColor: '#E5A703' }}
            ></div>
            <span className={accessoryPage === 2 ? 'bold' : ''}>Guardrails</span>
            <div
              className='height-ref-tile'
              style={{ backgroundColor: '#658DCC' }}
            ></div>
            <span className={accessoryPage === 3 ? 'bold' : ''}>Skirting</span>
          </div>
        </div>
      )}
      <div className='add-accessories-buttons'>
        <BasicButton
          onClick={() => {
            handleSetPage('prev');
          }}
          text={'Back'}
          type={'white-bordered-button'}
        />
        <BasicButton
          onClick={() => {
            handleSetPage('next');
          }}
          text={'Next'}
          type={'white-bordered-button'}
        />
      </div>
    </div>
  );
}

export default AddAccessories;
