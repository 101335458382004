import { useEffect, useState } from 'react';
import { IoChevronBack, IoChevronForward, IoLocationSharp } from 'react-icons/io5';
import '../CSS/Whatsnew.css';
// import { ImCalendar } from "react-icons/im";
// import DiscoverProducts from "../Components/HomePage/DiscoverProducts";
import NewLit from '../Components/WhatsNew/NewLit';
// import VideoPlayer from "../Components/Products/VideoPlayer";
import { BsChevronLeft, BsChevronRight, BsCircleFill } from 'react-icons/bs';

import Axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HomeCarousel from '../Components/HomePage/Carousel';
import useGeneralImages from '../hooks/useGeneralImages';
import FormatImageLink from '../Components/Shared/FormatImageLink';

const WhatsNew = () => {
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const banner = generalImages.find((i) => i.publicId === 'WhatsNew_a0icl7')?.imageLink;
  useEffect(() => {
    document.title = `What's New - National Public Seating`;
    window.scrollTo(0, 0);
  }, []);
  const mobileScreen = useSelector((state) => state.isMobile.value);
  const [literature, setLiterature] = useState();
  const [litIndex, setLitIndex] = useState(0);
  // const [events, setEvents] = useState();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [carouselIndexData, setCarouselIndexData] = useState();
  const [carouselData, setCarouselData] = useState();
  const [banners, setBanners] = useState();

  const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

  useEffect(() => {
    if (carouselData) {
      setCarouselIndexData(carouselData[carouselIndex]);
    }
  }, [carouselIndex, carouselData]);
  function getData() {
    Axios.get(`${apiAddress}/content/GetNewLit`).then((response) => {
      setLiterature(response.data.literature);
    });
    Axios.get(`${apiAddress}/content/getProductCarousel`).then((response) => {
      setCarouselData(response.data.carouselList);
    });
    Axios.post(`${apiAddress}/content/getCarouselBanners`, {
      page: 'whatsnew',
    }).then((response) => {
      if (mobileScreen.isMobile) {
        response.data = response.data.filter((i) => i.screen_type?.toLowerCase() === 'mobile');
      } else {
        response.data = response.data.filter((i) => i.screen_type?.toLowerCase() === 'desktop');
      }
      setBanners(response.data.filter((i) => i.carousel_type.toLowerCase() === 'main'));
    });
  }
  function addIndex() {
    if (carouselIndex === carouselData.length - 1) {
      setCarouselIndex(0);
    } else {
      setCarouselIndex(carouselIndex + 1);
    }
  }
  function removeIndex() {
    if (carouselIndex === 0) {
      setCarouselIndex(carouselData.length - 1);
    } else {
      setCarouselIndex(carouselIndex - 1);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  const buttonStyle = {
    height: '30px',
    width: '30px',

    color: '#B3B3B3',
    cursor: 'pointer',
    background: 'white',
    boxShadow: '2px 2px 8px #0000004D',
    borderRadius: '30rem',
  };
  const navigate = useNavigate();

  return (
    <div
      className='whatsnew'
      style={{ position: 'relative' }}
    >
      <img
        src={optimizeCloudinaryUrl(banner)}
        style={{ marginBottom: '0px' }}
        className='banner'
        alt='Whats New Banner'
      ></img>

      {/* <div
        className="video-div inner-padding"
        onClick={() => {
          setPlayVideo(true);
        }}
      >
        <div className="video-banner">
          <h3>New Video</h3>
          <h1>Titan Series</h1>
          <h2>Modern | Indestructible | Versatile</h2>
          <button>Watch the video</button>
        </div>
        <BsFillPlayCircleFill />
      </div>
      {playVideo ? (
        <VideoPlayer
          setShow={setPlayVideo}
          videoSrc={
            "https://res.cloudinary.com/da3rom333/video/upload/v1688060110/Product%20Assets/Videos/Patent_Pending_-_Titan_Series_by_National_Public_Seating_Video_pzkeez.mp4"
          }
        />
      ) : (
        ""
      )} */}
      <div
        className='inner-padding'
        style={{ margin: '4rem auto' }}
      >
        <HomeCarousel images={banners} />
      </div>
      <div
        className='newLit'
        style={{ position: 'relative' }}
      >
        <div className='inner-padding'>
          <h2>New Literature</h2>
          <div className='underline'></div>
          <h3>Have a look to our latest pieces of literature</h3>

          <div
            className='newLit-items'
            style={{ position: 'relative' }}
          >
            {literature?.length > 4 ?
              <div
                className='navigateButtons'
                style={{ position: 'absolute', right: '2rem', top: '-4rem' }}
              >
                <IoChevronBack
                  style={{ ...buttonStyle, padding: '8px 10px 8px 6px' }}
                  onClick={() => {
                    if (litIndex - 2 < 0) {
                      setLitIndex(literature?.length - 2);
                    } else {
                      setLitIndex(litIndex - 2);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (litIndex - 2 < 0) {
                        setLitIndex(literature?.length - 2);
                      } else {
                        setLitIndex(litIndex - 2);
                      }
                    }
                  }}
                />
                <IoChevronForward
                  style={{
                    ...buttonStyle,
                    padding: '8px 6px 8px 10px',
                    marginLeft: '16px',
                  }}
                  onClick={() => {
                    if (litIndex + 2 >= literature?.length) {
                      setLitIndex(0);
                    } else {
                      setLitIndex(litIndex + 2);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (litIndex + 2 >= literature?.length) {
                        setLitIndex(0);
                      } else {
                        setLitIndex(litIndex + 2);
                      }
                    }
                  }}
                />
              </div>
            : ''}
            {literature?.slice(litIndex, litIndex + 4)?.map((i, index) => {
              return (
                <NewLit
                  lit={i}
                  key={index}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className='newProducts'>
        <div
          className='inner-padding'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h2>NEW PRODUCTS</h2>
          <div className='underline'></div>
          <div className='carousel'>
            <BsChevronLeft
              onClick={removeIndex}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  removeIndex();
                }
              }}
            />
            <div className='carousel-data'>
              <div className='carousel-data-container'>
                <h1>{carouselIndexData?.title}</h1>
                <p>{carouselIndexData?.description}</p>
                <button
                  onClick={() => {
                    carouselIndexData?.link?.startsWith('http') ?
                      window.open(`${carouselIndexData?.link}`, '_blank')
                    : navigate(`/products/${carouselIndexData?.link}`);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      carouselIndexData?.link?.startsWith('http') ?
                        window.open(`${carouselIndexData?.link}`, '_blank')
                      : navigate(`/products/${carouselIndexData?.link}`);
                    }
                  }}
                >
                  View Item
                </button>
              </div>
              <img
                style={{
                  borderRadius: '1rem',
                  boxSizing: 'border-box',
                  objectFit: 'contain',
                  background: 'white',
                  padding: '1rem',
                }}
                src={
                  optimizeCloudinaryUrl(carouselIndexData?.image) ||
                  'https://res.cloudinary.com/da3rom333/image/upload/v1729007847/DONT%20MOVE/image-coming-soon_lzhzdk_sm6cln.jpg'
                }
                alt='product'
              ></img>
            </div>
            <BsChevronRight
              onClick={addIndex}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  addIndex();
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatsNew;
