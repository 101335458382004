import { useState } from 'react';
import FormatImageLink from '../Shared/FormatImageLink';

const Symbol = ({ data }) => {
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const [hover, setHover] = useState(false);
  return (
    <div
      className={`symbol ${hover ? 'symbol-hover' : ''}`}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {hover ?
        <p>{data.hover_data}</p>
      : <img
          src={optimizeCloudinaryUrl(data.image)}
          alt='Symbol'
        ></img>
      }
    </div>
  );
};

export default Symbol;
