import { useEffect, useState } from 'react';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import VideoPlayer from '../../Components/Products/VideoPlayer';
import CarouselDots from '../Shared/CarouselDots';
import FormatImageLink from '../Shared/FormatImageLink';
import { AdvancedImage } from '@cloudinary/react';
// import { useEffect } from "react";

const HomeCarousel = ({ images }) => {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const { decodeAndFormat } = FormatImageLink();
  function addIndex() {
    if (index === images?.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  }

  function removeIndex() {
    if (index === 0) {
      setIndex(images?.length - 1);
    } else {
      setIndex(index - 1);
    }
  }

  useEffect(() => {
    if (images?.length && images[index]?.image?.endsWith('.mp4')) {
      const videoElement = document.getElementById(`banner-video-${index}`);
      videoElement?.load();
      videoElement?.play();
      return;
    }

    if (images?.length > 1) {
      const timer = setInterval(() => {
        addIndex();
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [index]);

  const [openVideo, setOpenVideo] = useState(false);
  const [videoSrc, setVideoSrc] = useState();
  function openVideoFunction(src) {
    setOpenVideo(true);
    setVideoSrc(src);
  }
  return (
    <div>
      {openVideo ?
        <VideoPlayer
          videoSrc={videoSrc}
          setShow={setOpenVideo}
        />
      : ''}
      <div className='carouselOuterDiv'>
        {images?.length > 1 ?
          <button
            className='carouselButton'
            id='backButton'
            onClick={removeIndex}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                removeIndex();
              }
            }}
            aria-label='back'
          >
            <IoChevronBack />
          </button>
        : ''}

        <div className='carouselDiv'>
          {images?.map((i, indexIn) => {
            if (i.image.endsWith('.mp4')) {
              return (
                <video
                  key={indexIn}
                  src={i.image}
                  onClick={() => {
                    navigate(i.link);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      navigate(i.link);
                    }
                  }}
                  onEnded={(event) => {
                    const videoElement = event.target;
                    videoElement.pause();
                    addIndex();
                  }}
                  autoPlay
                  muted
                  className={index === indexIn ? 'selected' : ''}
                  id={`banner-video-${indexIn}`}
                />
              );
            } else {
              return (
                <AdvancedImage
                  cldImg={decodeAndFormat(i.image)}
                  alt='Home Page Banner'
                  key={i.image}
                  className={index === indexIn ? 'selected' : ''}
                  onClick={() => {
                    if (i.open_video) {
                      openVideoFunction(i.link);
                    } else {
                      navigate(i.link);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (i.open_video) {
                        openVideoFunction(i.link);
                      } else {
                        navigate(i.link);
                      }
                    }
                  }}
                />
              );
            }
          })}
        </div>
        {images?.length > 1 ?
          <button
            className='carouselButton'
            id='forwardButton'
            onClick={addIndex}
            aria-label='forward'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                addIndex();
              }
            }}
          >
            <IoChevronForward />
          </button>
        : ''}
      </div>
      <CarouselDots
        images={images}
        index={index}
        setIndex={setIndex}
      ></CarouselDots>
    </div>
  );
};

export default HomeCarousel;
