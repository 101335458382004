import Axios from 'axios';
import { useEffect, useState } from 'react';
import { FaPhoneAlt } from 'react-icons/fa';
import { IoSearchOutline } from 'react-icons/io5';
import { MdMail } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import useGeneralImages from '../../hooks/useGeneralImages';
import MouseFollower from '../../Components/Shared/MouseFollower';
import USAMap from '../../Components/Map/USAMap';
import FormatImageLink from '../../Components/Shared/FormatImageLink';

const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;

const DealerMap = () => {
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const searchBanner = generalImages.find((i) => i.publicId === 'SearchMap_jam2ml')?.imageLink;
  const locatorIcon = generalImages.find(
    (i) => i.publicId === 'Dealer_Locator_Icon_bjidna'
  )?.imageLink;
  const international = generalImages.find(
    (i) => i.publicId === 'Dealer_Locator_International_erpfge'
  )?.imageLink;
  const logo = generalImages.find(
    (i) => i.publicId === 'Dealer_Locator_NPS_blue_icon_g6oomp'
  )?.imageLink;
  const canada = generalImages.find(
    (i) => i.publicId === 'Dealer_Locator_Canada_xvoog1'
  )?.imageLink;
  const banner = generalImages.find((i) => i.publicId === 'DealerLocator_aecewc')?.imageLink;

  useEffect(() => {
    document.title = `Dealer Locator - National Public Seating`;
  }, []);

  const navigate = useNavigate();
  // const [open, setOpen] = useState(false);
  // const [mouseLocation, setMouseLocation] = useState(false);
  // const [location, setLocation] = useState(false);
  const [stateInfo, setStateInfo] = useState('');
  const [searchVal, setSearchVal] = useState('');
  const [dealerFound, setDealerFound] = useState();
  const [mapData, setMapData] = useState();
  const [stateDimensions, setStateDimensions] = useState();
  function onClick(email, location) {
    window.open(
      `mailto:${email}?cc=awalles@nationalpublicseating.com&subject=Mail From Dealer Locator Page for ${location}`
    );
  }

  function enterState(s) {
    setStateInfo(s);
    // setOpen(true);
  }

  function findByState(text) {
    var foundbyState = [];
    Object.keys(mapData).forEach((state) => {
      if (mapData[state].State.toLowerCase() === text.toLowerCase()) {
        foundbyState.push(mapData[state]);
      }
    });

    if (foundbyState.length) {
      return foundbyState[0];
    }
  }
  async function searchLocation() {
    const foundByAbbr = mapData[searchVal.toUpperCase()];
    if (foundByAbbr) {
      setDealerFound(foundByAbbr);
    } else {
      const foundByState = findByState(searchVal);
      if (foundByState) {
        setDealerFound(foundByState);
      } else {
        const response = await Axios.post(`${apiAddress}/content/getZipInfo`, {
          zip: searchVal,
        });
        const foundByZip = response?.data[0]?.State;
        // Assuming you want to set the dealer found by zip info
        if (foundByZip) {
          //   const found = mapData[foundByZip.toUpperCase()];
          setDealerFound(mapData[foundByZip.toUpperCase()]);
        } else {
          // Handle case when none of the methods return a result
          setDealerFound();
          console.error('No dealer found for the provided search value.');
        }
      }
    }
  }
  async function getMapData() {
    try {
      const response = await Axios.get(`${apiAddress}/content/getMapData`);
      setMapData(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  async function getMapDimensions() {
    try {
      const response = await Axios.get(`${apiAddress}/content/getMapDimensions`);
      setStateDimensions(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getMapData();
    getMapDimensions();
  }, []);

  useEffect(() => {}, [stateDimensions, mapData]);

  useEffect(() => {}, [stateInfo]);
  return (
    <div className='dealer-map'>
      <div className='banner'>
        <img
          src={optimizeCloudinaryUrl(banner)}
          alt='Dealer Locator Banner'
        ></img>
        <div className='searchDealer'>
          <div className='search'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={optimizeCloudinaryUrl(locatorIcon)}
                alt='Location Icon'
              ></img>
              <h1>Find Your Nearest Dealer</h1>
            </div>
            <div className='input-div'>
              <input
                type='text'
                value={searchVal}
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
                placeholder='Enter zip or state'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    searchLocation();
                  }
                }}
              ></input>
              <IoSearchOutline onClick={searchLocation} />
            </div>
          </div>
          <div>
            {dealerFound ?
              <div
                className='dealer-found dealer-info-div'
                style={{ marginRight: '30px' }}
              >
                <div>
                  <h3>
                    {dealerFound.State} ({dealerFound.StateAbbr})
                  </h3>
                  <div className='underline'></div>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <h4>
                        Account Manager:
                        {dealerFound.managers.find((i) => i.type === 'account').name}
                      </h4>
                      <h5>
                        <FaPhoneAlt />
                        {dealerFound.managers.find((i) => i.type === 'account').phone_number}
                      </h5>
                      <h5>
                        <MdMail />
                        {dealerFound.managers.find((i) => i.type === 'account').email}
                      </h5>
                    </div>
                    <div style={{ marginLeft: '80px' }}>
                      <h4>
                        Account Manager:
                        {dealerFound.managers.find((i) => i.type === 'relationship').name}
                      </h4>
                      <h5>
                        <FaPhoneAlt />
                        {dealerFound.managers.find((i) => i.type === 'relationship').phone_number}
                      </h5>
                      <h5>
                        <MdMail />
                        {dealerFound.managers.find((i) => i.type === 'relationship').email}
                      </h5>
                    </div>
                  </div>
                  <button
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      onClick(
                        dealerFound.managers.find((i) => i.type === 'account').email,
                        dealerFound.State
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onClick(
                          dealerFound.managers.find((i) => i.type === 'account').email,
                          dealerFound.State
                        );
                      }
                    }}
                  >
                    Contact
                  </button>
                </div>
              </div>
            : <img
                src={optimizeCloudinaryUrl(searchBanner)}
                className='searchBanner'
                alt='Search Icon'
              ></img>
            }
          </div>
        </div>
      </div>

      <div className='map-outer-div '>
        <div className='inner-padding'>
          <h2>
            Simply hover on your location below and our National Public Seating Representative will
            direct you to the factory-authorized servicing dealer in your area.
          </h2>

          {stateDimensions && mapData && (
            <USAMap
              dataStates={stateDimensions}
              dealerData={mapData}
              onClick={onClick}
              onEnterState={enterState}
              onLeaveState={() => {
                setStateInfo('');
                // setOpen(false);
              }}
            />
          )}
          <MouseFollower element={stateInfo} />
          <div className='canadaInternational'>
            <div
              className='canada dealer-info-div'
              style={{ marginRight: '30px' }}
            >
              <img
                src={optimizeCloudinaryUrl(canada)}
                alt='Canada Banner'
              ></img>
              <div>
                <h3>Canada</h3>
                <div className='underline'></div>
                <h4>Account Manager: Jay Leiser</h4>
                <h5>
                  <FaPhoneAlt /> 800-261-4112 Ext. 132
                </h5>
                <h5>
                  <MdMail />
                  jleiser@nationalpublicseating.com
                </h5>
                <button
                  onClick={() => {
                    onClick('jleiser@nationalpublicseating.com', 'Canada');
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onClick('jleiser@nationalpublicseating.com', 'Canada');
                    }
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Contact
                </button>
              </div>
            </div>
            <div className='canada dealer-info-div'>
              <img
                src={optimizeCloudinaryUrl(international)}
                alt='International'
              ></img>
              <div>
                <h3>INTERNATIONAL DEALER</h3>
                <div className='underline'></div>
                <h4>Account Manager: Neil Horowitz</h4>
                <h5>
                  <FaPhoneAlt /> 800-261-4112 Ext. 186
                </h5>
                <h5>
                  <MdMail />
                  nhorowitz@nationalpublicseating.com
                </h5>
                <button
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    onClick('nhorowitz@nationalpublicseating.com', 'International');
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onClick('nhorowitz@nationalpublicseating.com', 'International');
                    }
                  }}
                >
                  Contact
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: '120rem' }}></div>
      <div className='feedback inner-padding'>
        <img
          src={optimizeCloudinaryUrl(logo)}
          alt='NPS Logo'
        ></img>
        <h3>
          National Public Seating Dealers, Distributors & Wholesalers are your source for
          institutional quality products engineered with craftsmanship and skill and have been
          designed to exceed all your expectations. Our dealers strive for excellence and share our
          same high standards of service.
        </h3>
        <p>
          Note: At National Public Seating we pride ourselves on our elite dealer network. We only
          sell through highly trained distributors who understand the needs of the marketplace. We
          welcome your comments and appreciate your feedback.
        </p>
        <button
          onClick={() => {
            navigate('/contact?category=contact-us');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              navigate('/contact?category=contact-us');
            }
          }}
        >
          Give Feedback
        </button>
      </div>
    </div>
  );
};

export default DealerMap;
