import { useEffect, useState } from 'react';
// import "../CSS/EditorTools.css";
import '../CSS/EditorPortal.css';
// import EditTemplate from "../Components/EditorTools/EditTemplate";
import { useNavigate } from 'react-router-dom';
import useExportToExcel from '../Components/Shared/useExportToExcel';
import UpdateImageTable from '../Components/UpdateImageTable';
import useAxiosJWT from '../hooks/useAxiosJWT';

const EditorPortalNew = () => {
  const navigate = useNavigate();
  const axiosJWT = useAxiosJWT();
  const ExportToExcel = useExportToExcel();
  const [categories, setCategories] = useState();
  const [hoverCategory, setHoverCategory] = useState();
  // const [hoverItem, setHoverItem] = useState();
  const [warning, setWarning] = useState();

  async function getEditCategories() {
    await axiosJWT
      .post(`adminPortal/getEditCategories`, { page: 'edit' })
      .then((response) => {
        const groupedData = {};
        response.data.forEach((item) => {
          const { parent_ID, parent_title, ...rest } = item;

          const key = `${parent_ID}_${parent_title}`;
          if (!groupedData[key]) {
            groupedData[key] = {
              ID: parent_ID,
              title: parent_title,
              tabs: [],
            };
          }

          groupedData[key].tabs.push(rest);
        });

        const result = Object.values(groupedData);

        setCategories(result);
        setHoverCategory(result[0]);
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {
    getEditCategories();
  }, []);

  function getSkuList() {
    axiosJWT
      .get('adminPortal/getSeriesList')
      .then((res) => {
        ExportToExcel(res.data, 'skulist');
      })
      .catch((err) => [console.error(err)]);
  }

  function getQuickshipSkus() {
    axiosJWT
      .get('adminPortal/getQuickshipSkus')
      .then((res) => {
        ExportToExcel(res.data, 'quickship skus');
      })
      .catch((err) => [console.error(err)]);
  }

  function getSortedPricer(pricer, route) {
    console.log(pricer, route);
    axiosJWT
      .post(`adminPortal/${route}`, { pricer })
      .then((res) => {
        ExportToExcel(res.data, 'pricer');
      })
      .catch((err) => [console.error(err)]);
  }

  function handleClick(table) {
    if (table?.route_name) {
      if (table.route_name === 'getSkuList') {
        getSkuList();
      } else if (table.route_name === 'getQuickshipSkus') {
        getQuickshipSkus();
      } else if (table.route_name === 'imageImport') {
        setWarning(table.table_name);
      } else if (
        table.route_name === 'getSortedPricer' ||
        table.route_name === 'getSortedDDPricer'
      ) {
        getSortedPricer(table.specification, table.route_name);
      } else {
        navigate(`/${table.route_name}`);
      }
    } else {
      navigate(`/editTable/${table.title}`);
    }
  }

  return (
    <div
      style={{ minHeight: '40rem' }}
      className='adminDiv'
    >
      <h2>Editor Portal</h2>
      <div
        className='inner-padding '
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {categories
            ?.sort((a, b) => a.ID - b.ID)
            ?.map((i, index) => {
              return (
                <div
                  key={index}
                  style={{ display: 'flex' }}
                >
                  <button
                    style={{ marginTop: categories[0] === i ? '0px' : '7px' }}
                    onClick={() => {
                      setHoverCategory(i);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setHoverCategory(i);
                      }
                    }}
                    className={`editor-portal-button ${hoverCategory === i ? 'clicked' : ''}`}
                    key={index}
                  >{`${i.title}`}</button>
                </div>
              );
            })}
        </div>
        <div
          className='hover-items-div'
          style={{
            borderTopLeftRadius:
              hoverCategory && categories ?
                hoverCategory === categories[0] ?
                  '0px'
                : '1.5rem'
              : '',
            borderBottomLeftRadius:
              hoverCategory && categories ?
                hoverCategory === categories[categories.length - 1] ?
                  '0px'
                : '1.5rem'
              : '',
          }}
        >
          {hoverCategory?.tabs
            ?.sort((a, b) => a.title.localeCompare(b.title))
            ?.map((j, index) => (
              <div
                role='button'
                aria-label={`Go to ${j.title}`}
                tabIndex={0}
                key={index}
                // onMouseEnter={() => setHoverItem(j)}
                // onMouseLeave={() => setHoverItem(null)}
                onClick={() => handleClick(j)}
                className='hover-item'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleClick(j);
                  }
                }}
              >
                {j.title}
              </div>
            ))}
        </div>
      </div>
      {warning && (
        <UpdateImageTable
          setWarning={setWarning}
          table={warning}
        />
      )}
    </div>
  );
};

export default EditorPortalNew;
