import { useEffect, useState } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import { BsCircle, BsCircleFill } from 'react-icons/bs';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import useGeneralImages from '../../hooks/useGeneralImages';
import InputField from './InputField';
import FormatImageLink from '../Shared/FormatImageLink';

const ClaimForm = ({ setShowForm, showForm, selectedButton }) => {
  const { generalImages } = useGeneralImages();
  const [formValues, setFormValues] = useState({});
  const [formPage, setFormPage] = useState(0);
  const [continueValid, setContinueValid] = useState(false);
  const [submitComplete, setSubmitComplete] = useState('');
  const [inputFields, setInputFields] = useState();
  const axiosJWT = useAxiosJWT();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const check = generalImages.find((i) => i.publicId === 'green_check_d3olgm')?.imageLink;

  function getData() {
    axiosJWT.post(`content/getFormFields`).then((response) => {
      const formInputs = response.data.formfields;
      setInputFields(
        formInputs?.filter((i) => {
          return i['Form Name'].toLowerCase() === showForm.replace(/-/g, ' ');
        })
      );
      var holderObj = {};
      formInputs
        ?.filter((i) => i['Form Name'].toLowerCase() === showForm.replace(/-/g, ' '))
        ?.forEach((i) => {
          holderObj[i['Input Name']] = '';
        });
      setFormValues(holderObj);
    });
  }

  function setFiles(files) {
    setFormValues({ ...formValues, files: files });
  }
  function checkRequiredFields() {
    const requiredFields = inputFields
      ?.filter((field) => field.Page === formPage + 1)
      .filter((field) => field.Required === 'TRUE');
    if (requiredFields) {
      for (const field of requiredFields) {
        if (!formValues[field['Input Name']]) {
          return false;
        }
      }
    }
    return true;
  }

  function onInputchange(event) {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  }

  function continueHandler(event) {
    event.preventDefault();
    setFormPage(1);
  }

  function saveForm() {
    const formData = new FormData();
    if (formValues?.files) {
      Object.values(formValues.files).forEach((file) => {
        formData.append('arrayOfFiles', file);
      });
    }
    formData.append('formValues', JSON.stringify(formValues));
    axiosJWT
      .post(`forms/${showForm.replace('-', '').replace('-', '').replace('/', 'or')}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setSubmitComplete('success');
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setSubmitComplete('error');
      });
  }

  function submitHandler(event) {
    event.preventDefault();
    if (checkRequiredFields()) {
      saveForm();
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    var checkFields = checkRequiredFields();
    if (checkFields) {
      setContinueValid(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formValues.email));
    } else {
      setContinueValid(false);
    }
  }, [formValues, formPage]);

  return (
    <div className='claimForm'>
      <div
        role='button'
        aria-label='Back'
        tabIndex={0}
        onClick={() => {
          setShowForm(null);
        }}
        className='back'
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setShowForm(null);
          }
        }}
      >
        <AiOutlineLeft />
        Back
      </div>
      <div className='headers'>
        <h2>{selectedButton.replace(/-/g, ' ')}</h2>
        <h3>
          NPS invests much time and effort to ensure that all of our products are manufactured with
          the highest standard and that every order is processed correctly. We appreciate the
          opportunity to assist you at any time.
        </h3>
      </div>
      {!submitComplete && formPage === 0 ?
        <form>
          <h2>
            {showForm === 'something-else' ?
              'Need Something Else?'
            : `${showForm.replace(/-/g, ' ')} Form`}
          </h2>
          <div className='underline'></div>
          {inputFields
            ?.filter((i) => {
              return i['Page'] === formPage + 1;
            })
            ?.map((i, index) => {
              return (
                <InputField
                  key={index}
                  data={i}
                  onInputchange={onInputchange}
                  formValues={formValues}
                  files={formValues.files}
                  setFiles={setFiles}
                />
              );
            })}
          {showForm === 'something-else' ?
            <>
              <button
                onClick={(e) => {
                  if (continueValid) {
                    submitHandler(e);
                  } else {
                    e.preventDefault();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (continueValid) {
                      submitHandler(e);
                    } else {
                      e.preventDefault();
                    }
                  }
                }}
                className={`${continueValid ? 'valid-continue' : 'invalid-continue'}`}
              >
                Submit
              </button>
            </>
          : <>
              <button
                onClick={(e) => {
                  if (continueValid) {
                    continueHandler(e);
                  } else {
                    e.preventDefault();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (continueValid) {
                      continueHandler(e);
                    } else {
                      e.preventDefault();
                    }
                  }
                }}
                className={`${continueValid ? 'valid-continue' : 'invalid-continue'}`}
              >
                Continue
              </button>
              <div className='pagePointer'>
                <BsCircleFill />
                <BsCircle
                  onClick={() => {
                    if (continueValid) {
                      setFormPage(1);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (continueValid) {
                        setFormPage(1);
                      }
                    }
                  }}
                />
              </div>
            </>
          }
          <div></div>
        </form>
      : !submitComplete && formPage === 1 ?
        <form>
          <h2>
            {showForm === 'something-else' ?
              'Need Something Else?'
            : `${showForm.replace(/-/g, ' ')} Form`}
          </h2>
          <div className='underline'></div>
          {inputFields
            ?.filter((i) => {
              return i['Page'] === formPage + 1;
            })
            ?.map((i, index) => {
              return (
                <InputField
                  key={index}
                  data={i}
                  onInputchange={onInputchange}
                  formValues={formValues}
                  files={formValues.files}
                  setFiles={setFiles}
                />
              );
            })}
          <button
            onClick={(e) => {
              if (continueValid) {
                submitHandler(e);
              } else {
                e.preventDefault();
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (continueValid) {
                  submitHandler(e);
                } else {
                  e.preventDefault();
                }
              }
            }}
            className={`${continueValid ? 'valid-continue' : 'invalid-continue'}`}
          >
            Submit
          </button>
          <div className='pagePointer'>
            <BsCircle
              onClick={() => {
                setFormPage(0);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setFormPage(0);
                }
              }}
            />
            <BsCircleFill />
          </div>
        </form>
      : submitComplete === 'success' ?
        <form className='feedbackComplete'>
          <img
            src={optimizeCloudinaryUrl(check)}
            alt='Check Icon'
          />
          <h2>Thank you for submitting your claim!</h2>
          <p>
            {/* We've received your missing parts form and are currently reviewing
            your request. We'll be in touch with you soon to update you on the
            status of your order. */}
            {inputFields?.filter((i) => i['Input Name'] === 'success')[0]?.['Placeholder text']}
          </p>
          <div className='underline'></div>
        </form>
      : submitComplete === 'error' ?
        <form className='feedbackComplete'>
          <BiError style={{ height: '11rem', width: '11rem', color: '#b90202' }} />
          <h2>
            Oops! Something went wrong while submitting your claim. Please try submitting your claim
            again in a few minutes. If the issue persists, contact our support team for assistance.
          </h2>
          <div className='underline'></div>
        </form>
      : ''}
    </div>
  );
};

export default ClaimForm;
