import Axios from 'axios';
import { useEffect, useState } from 'react';
import { FaQuoteLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../../CSS/Testimonials.css';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../../Components/Shared/FormatImageLink';

const Testimonials = () => {
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const banner = generalImages.find((i) => i.publicId === 'testimonials_eluytj')?.imageLink;
  const navigate = useNavigate();
  const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;
  const [aboutTestimonials, setAboutTestimonials] = useState([]);

  function getAboutTestimonials() {
    Axios.get(`${apiAddress}/content/getAboutTestimonials`)
      .then((response) => {
        setAboutTestimonials(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    getAboutTestimonials();
  }, []);

  useEffect(() => {
    document.title = `Testimonials - National Public Seating`;
    window.scrollTo(0, 0);
  });

  return (
    <div className='testimonialsPage'>
      <div className='banner'>
        <img
          src={optimizeCloudinaryUrl(banner)}
          alt='Testimonials Banner'
        ></img>
      </div>
      {/* <div style={{ height: "17rem" }}></div> */}

      <div className='testimonials-div inner-padding'>
        <div className=' left-testimonials'>
          <div className='title inner-padding'>
            <h1>
              What <span style={{ fontWeight: 'bold' }}>People</span> Say
              <span style={{ fontWeight: 'bold' }}>About</span> Us
            </h1>
            <div className='underline'></div>
            <div className='h3'>Your voice shapes our growth; we listen to evolve.</div>
            <button
              onClick={() => {
                navigate('/contact?category=contact-us');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  navigate('/contact?category=contact-us');
                }
              }}
            >
              Submit Feedback
            </button>
          </div>
          <div className='testimonials'>
            {aboutTestimonials
              .filter((_, index) => index < Math.ceil(aboutTestimonials.length / 2))
              .map((i, index) => {
                return (
                  <div
                    key={index}
                    className='testimonial'
                  >
                    <FaQuoteLeft />
                    <p>{i.comment}</p>
                    <div className='h3'>{i.name}</div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className='testimonials right-testimonials'>
          {aboutTestimonials
            .filter((_, index) => index >= Math.ceil(aboutTestimonials.length / 2))
            .map((i, index) => {
              return (
                <div
                  key={index}
                  className='testimonial'
                >
                  <FaQuoteLeft />
                  <p>{i.comment}</p>
                  <div className='h3'>{i.name}</div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
