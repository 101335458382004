import { useEffect, useState } from 'react';
// import ImageWithHover from "../Components/EditorPortal/ImageWithHover";
import UploadFile from '../Components/Shared/UploadFile';
import useAxiosJWT from '../hooks/useAxiosJWT';
import FormatImageLink from '../Components/Shared/FormatImageLink';
const TestCloudinary = () => {
  const [images, setImages] = useState();
  const [searchVal, setSearchVal] = useState('');
  const [searchUrl, setSearchUrl] = useState('');
  // const [replaceUrl, setReplaceUrl] = useState([]);
  // Define your metadata query parameters
  const [type, setType] = useState('sku');
  const [metadataImport, setMetadataImport] = useState();
  const { optimizeCloudinaryUrl } = FormatImageLink();

  useEffect(() => {
    updateMetadata();
  }, [metadataImport]);
  const axiosJWT = useAxiosJWT();
  function getData() {
    axiosJWT
      .post(`/cloudinary/getImagesBy${type}`, { [type]: searchVal })
      .then((response) => {
        if (type === 'series') {
          setImages(response.data.map((resource) => resource.link));
        } else {
          setImages(response.data);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  // function replaceCloudinaryAsset() {
  //   const formData = new FormData();
  //   Object.values(replaceUrl).forEach((file) => {
  //     formData.append('arrayOfFiles', file);
  //   });
  //   formData.append('urlToBeReplaced', searchUrl);
  //   axiosJWT
  //     .post(`/cloudinary/replaceCloudinaryAsset`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     })
  //     .then((response) => {
  //     })
  //     .catch((err) => {
  //     });
  // }

  function removeMetadata() {
    axiosJWT
      .post(`/cloudinary/removeMetadata`, { imageLinks: [searchUrl] })
      .then((response) => {
        console.error(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getMetadata() {
    axiosJWT
      .post(`/cloudinary/getMetadata`, { link: searchUrl })
      .then((response) => {
        console.error(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function updateMetadata() {
    axiosJWT
      .post(`/cloudinary/updateMetadata`, {
        metadataImport: metadataImport,
      })
      .then((response) => {
        console.error(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  return (
    <div className='inner-padding'>
      <input
        value={searchVal}
        onChange={(e) => {
          setSearchVal(e.target.value);
        }}
        placeholder='Enter sku to search'
      ></input>
      <button
        onClick={getData}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            getData();
          }
        }}
      >
        test
      </button>
      <button
        onClick={() => {
          setType('sku');
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setType('sku');
          }
        }}
        style={{ backgroundColor: type === 'sku' ? 'green' : 'lightgrey' }}
      >
        Sku
      </button>
      <button
        onClick={() => {
          setType('series');
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setType('series');
          }
        }}
        style={{ backgroundColor: type === 'series' ? 'green' : 'lightgrey' }}
      >
        Series
      </button>
      <div>
        {images?.map((i, index) => {
          return (
            <img
              key={index}
              alt={i}
              src={optimizeCloudinaryUrl(i)}
              style={{ height: '4rem', width: '4rem' }}
            ></img>
          );
        })}
      </div>
      <div>
        <input
          type='text'
          value={searchUrl}
          onChange={(e) => {
            setSearchUrl(e.target.value);
          }}
          style={{ width: '25rem' }}
        ></input>

        <img
          src={optimizeCloudinaryUrl(searchUrl) || ''}
          style={{ height: '2rem' }}
          alt={searchUrl}
        ></img>
        {/* {searchUrl && <ImageWithHover src={searchUrl} />}
        <input
          type="file"
          // value={`${replaceUrl}`}
          onChange={(e) => {
            setReplaceUrl([e.target.files[0]]);
          }}
        ></input> */}
        <button
          onClick={removeMetadata}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              removeMetadata();
            }
          }}
        >
          Remove Metadata
        </button>
        <button
          onClick={updateMetadata}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              updateMetadata();
            }
          }}
        >
          Update Metadata
        </button>
        <button
          onClick={getMetadata}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              getMetadata();
            }
          }}
        >
          Get Metadata
        </button>
        <UploadFile
          text={'Import Metadata'}
          setData={setMetadataImport}
        />
      </div>
    </div>
  );
};

export default TestCloudinary;
