import React from 'react';
import { useNavigate } from 'react-router';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../Shared/FormatImageLink';
const NotFound = ({ title }) => {
  const navigate = useNavigate();
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const notfound = generalImages.find((i) => i.publicId === '404_Page_not_found_reu7lh')?.imageLink;
  const categoryNotFound = generalImages.find(
    (i) => i.publicId === '404_Category_not_found_frsog3'
  )?.imageLink;
  return (
    <div style={{ marginTop: '6rem', position: 'relative' }}>
      {title === 'product' ?
        <img
          src={optimizeCloudinaryUrl(notfound)}
          style={{ width: '100%' }}
          alt='Product Not Found'
        />
      : title === 'category' ?
        <img
          src={optimizeCloudinaryUrl(categoryNotFound)}
          style={{ width: '100%' }}
          alt='Category Not Found'
        />
      : ''}
      <button
        className='go-home-button'
        onClick={() => {
          navigate('/');
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            navigate('/');
          }
        }}
        style={{ top: '33rem', position: 'absolute' }}
      >
        Go Home
      </button>
    </div>
  );
};

export default NotFound;
