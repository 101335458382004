import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import Loading from '../Shared/Loading';
import useExportToExcel from '../Shared/useExportToExcel';
import EditableFormDesign from './EditableForm';
const EditTemplate = ({
  columns = false,
  dataIncoming = false,
  modelNums = false,
  getEndPoint,
  title,
  table,
  searchValue = false,
  addHandlerIncoming = false,
  saveHandlerIncoming = false,
  exportAvailable = false,
  importBatch = false,
  exportTemplate = false,
  importUpdate = false,
  clickHandler = false,
  backHandler = false,
  exportEndPoint = false,
  localNotice = false,
  setLocalNotice = false,
  accountRoles = false,
  specificColumn = false,
  specification = false,
  setShowAddAccount = false,
  searchInput = false,
  textDesc = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [search, setSearch] = useState();
  const axiosJWT = useAxiosJWT();
  const [notice, setNotice] = useState();
  const [editColumns, setEditColumns] = useState(columns || []);
  const { envID } = useParams();
  const ExportToExcel = useExportToExcel();

  function exportHandler() {
    if (exportEndPoint) {
      axiosJWT.get(`adminPortal/${exportEndPoint}`).then((response) => {
        ExportToExcel(response.data, table);
      });
    } else {
      if (getEndPoint === 'getGenericData') {
        axiosJWT
          .post(`adminPortal/getGenericData`, {
            table: table,
          })
          .then((response) => {
            ExportToExcel(
              response.data,

              table
            );
          });
      } else {
        axiosJWT.get(`adminPortal/${getEndPoint}`).then((response) => {
          ExportToExcel(
            response.data,

            table
          );
        });
      }
    }
  }

  function batchImportHandler(data) {
    if (data?.length) {
      if (
        !editColumns
          ?.filter((i) => i.name !== 'ID')
          ?.every((i) => Object?.keys(data[0])?.includes(i.name))
      ) {
        setNotice('Missing Column Headers');
        return;
      }
      const transformedData = data
        ?.filter((row, index) => {
          return (
            Object.keys(row).some(
              (value) => row[value] !== null && row[value] !== '' && row[value] !== undefined
            ) && index !== 0
          );
        })
        .map((row) => {
          const newRow = { ...row };
          for (const key in newRow) {
            if (typeof newRow[key] === 'object' && newRow[key] !== null) {
              const firstPropValue = Object.values(newRow[key])[0];
              newRow[key] = firstPropValue;
            }
          }
          return newRow;
        });

      transformedData.forEach((item) => {
        addCloudinaryPublicIds(item);
      });
      axiosJWT
        .post(`adminPortal/replaceTableData`, {
          table: table,
          data: transformedData,
          columns: editColumns,
        })
        .then(() => {
          setNotice('Batch Imported');
          getData();
        })
        .catch((err) => {
          setNotice(err);
        });
    } else {
      setNotice('No Data Imported');
    }
  }

  function uploadImportHandler(data) {
    if (data?.length) {
      if (
        !editColumns
          ?.filter((i) => i.name !== 'ID')
          ?.every((i) => Object?.keys(data[0])?.includes(i.name))
      ) {
        setNotice('Missing Column Headers');
        return;
      }
      const transformedData = data
        ?.filter((row, index) => {
          return (
            Object.keys(row).some(
              (value) => row[value] !== null && row[value] !== '' && row[value] !== undefined
            ) && index !== 0
          );
        })
        .map((row) => {
          const newRow = { ...row };
          for (const key in newRow) {
            if (typeof newRow[key] === 'object' && newRow[key] !== null) {
              const firstPropValue = Object.values(newRow[key])[0];
              newRow[key] = firstPropValue;
            }
          }
          return newRow;
        });

      transformedData.forEach((item) => {
        addCloudinaryPublicIds(item);
      });

      axiosJWT
        .post(`adminPortal/addTableData`, {
          table: table,
          data: transformedData,
          columns: editColumns,
        })
        .then(() => {
          setNotice('Import Completed');
          getData();
        })
        .catch((err) => {
          setNotice(err?.message);
        });
    } else {
      setNotice('No Data Imported');
    }
  }

  function saveHandler(values) {
    addCloudinaryPublicIds(values);

    axiosJWT
      .post(`adminPortal/updateData`, {
        table: table,
        values,
        columns: editColumns,
        modelNums: modelNums,
      })
      .then(() => {
        setNotice('SAVED');
      })
      .catch((err) => {
        setNotice(`ERROR SAVING: ${err} `);
      });
  }

  function addHandler(values) {
    //add public id for cloudinary images
    addCloudinaryPublicIds(values);
    axiosJWT
      .post(`adminPortal/addData`, {
        table: table,
        values: editColumns
          .filter(
            (i) =>
              (i.display || i.type === 'cloudinaryPublicId') &&
              (Object.keys(i).includes('includeInUpdate') ? i.includeInUpdate : true)
          )
          .map((i) => i.name)
          .map((i) => values[i]),
        keys: editColumns
          .filter(
            (i) =>
              (i.display || i.type === 'cloudinaryPublicId') &&
              (Object.keys(i).includes('includeInUpdate') ? i.includeInUpdate : true)
          )
          .map((i) => i.fieldName),
        modelNums: modelNums,
      })
      .then((response) => {
        getData();
        setNotice(`DATA ADDED ${!dataIncoming ? ` ID: ${response.data.insertId} ` : ''}`);
      })
      .catch((err) => {
        setNotice(`ERROR ADDING DATA: ${err}`);
      });
  }

  function addCloudinaryPublicIds(values) {
    editColumns.forEach((c) => {
      if (c.type === 'cloudinaryPublicId' && c.values?.length) {
        const cloudinaryLink = values[editColumns?.find((i) => i.fieldName === c?.values[0])?.name];
        if (cloudinaryLink.startsWith('https://res.cloudinary.com')) {
          values[c.name] = cloudinaryLink.substring(
            cloudinaryLink.lastIndexOf('/') + 1,
            cloudinaryLink.lastIndexOf('.')
          );
        }
      }
    });
  }

  function removeHandler(dataLine) {
    if (title === 'User Management') {
      axiosJWT.post(`adminPortal/removeAccountForUser`, {
        userID: dataLine.ID,
        accountID: dataLine?.Accounts[0]?.account_num,
        table: table,
      });
    } else {
      axiosJWT.post(`adminPortal/removeData`, {
        ID: data.ID || dataLine.ID,
        table: table,
        updateColumn: data,
        columns: editColumns,
        modelNums: modelNums,
      });
    }
  }

  function exportTemplateHanlder() {
    var columnObject = {};
    editColumns
      .filter((i) => {
        return i.name !== 'ID';
      })
      .map((i) => {
        columnObject[i.name] = '';
      });
    ExportToExcel([columnObject], 'Template');
  }

  async function getData() {
    setLoading(true);
    if (dataIncoming) {
      const data = await dataIncoming();
      setData(data);
      setLoading(false);
    } else if (getEndPoint === 'getGenericData') {
      axiosJWT
        .post(`adminPortal/getGenericData`, {
          table: table,
          specificColumn: specificColumn,
          specification: `'${specification}'`,
        })
        .then((response) => {
          setData(response.data);
          setLoading(false);
        });
    } else if (searchValue) {
      axiosJWT.post(`adminPortal/${getEndPoint}`, { search: search }).then((response) => {
        setData(response.data);
        setLoading(false);
      });
    } else if (envID) {
      axiosJWT.post(`adminPortal/${getEndPoint}`, { envID: envID }).then((response) => {
        setData(response.data);
        setLoading(false);
      });
    } else if (getEndPoint === 'getUsersForAccount') {
      axiosJWT.post(`account/${getEndPoint}`).then((response) => {
        setData(response.data);
        setLoading(false);
      });
    } else {
      axiosJWT.get(`adminPortal/${getEndPoint}`).then((response) => {
        setData(response.data);
        setLoading(false);
      });
    }
  }

  async function getColumns() {
    var finalResponse;
    try {
      const response = await axiosJWT.post(`/adminPortal/getColumns`, {
        table,
      });
      if (dataIncoming) {
        finalResponse = response.data.filter((i) => i.fieldName !== 'model_num');
      } else {
        finalResponse = response.data;
      }

      setEditColumns(finalResponse);
    } catch (error) {
      console.error('Error fetching columns:', error);
    }
  }

  useEffect(() => {
    if (data?.length) {
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (localNotice) {
      setNotice(localNotice);
    }
  }, [localNotice]);

  useEffect(() => {
    if (notice) {
      const timer = setTimeout(() => {
        setNotice();
      }, 3000);

      return () => clearTimeout(timer);
    }
    if (localNotice) {
      const timer = setTimeout(() => {
        setLocalNotice();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [notice]);

  useEffect(() => {
    if (!columns) {
      getColumns();
    }
  }, []);

  useEffect(() => {
    if (!searchValue) {
      getData();
    }
  }, [editColumns]);

  return (
    <>
      {!loading ?
        <div>
          {searchValue ?
            <div style={{ position: 'absolute', top: '15rem', left: '15rem' }}>
              Search
              <input
                style={{ border: '1px solid', marginLeft: '10px' }}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    getData();
                  }
                }}
              ></input>
            </div>
          : ''}

          <EditableFormDesign
            title={title}
            data={data}
            columns={editColumns}
            saveHandler={saveHandlerIncoming ? saveHandlerIncoming : saveHandler}
            addHandler={
              (
                table === 'pricer' ||
                table === 'users' ||
                title === 'Nondealer Requests' ||
                title === 'Pending Approvals' ||
                title === 'Route Requests'
              ) ?
                null
              : addHandlerIncoming ?
                addHandlerIncoming
              : addHandler
            }
            removeHandler={table === 'pricer' ? null : removeHandler}
            getData={getData}
            exportAvailable={exportAvailable}
            exportHandler={exportHandler}
            importBatch={importBatch}
            exportTemplateHanlder={exportTemplate ? exportTemplateHanlder : null}
            importUpdate={importUpdate}
            batchImportHandler={batchImportHandler}
            uploadImportHandler={uploadImportHandler}
            notice={notice}
            clickHandler={clickHandler}
            backHandler={backHandler}
            accountRoles={accountRoles}
            setShowAddAccount={setShowAddAccount}
            searchInput={searchInput}
            textDesc={textDesc}
          />
        </div>
      : <Loading />}
    </>
  );
};

export default EditTemplate;
