export const alphabetizeList = (list, sortItem) => {
  return list.sort((a, b) => {
    const [firstA, lastA = ''] = (a?.[sortItem] ?? '').split(' ');
    const [firstB, secondB = ''] = (b?.[sortItem] ?? '').split(' ');
    const firstNameComparison = firstA.localeCompare(firstB, {
      sensitivity: 'base',
    });
    return firstNameComparison ? firstNameComparison : (
        lastA.localeCompare(secondB, { sensitivity: 'base' })
      );
  });
};
