import React from 'react';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from './FormatImageLink';

const Loading = () => {
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const icon = generalImages.find((i) => i.publicId === 'LoadingBig_pmqwoe')?.imageLink;

  return (
    <div>
      <div className='modal'></div>
      <img
        src={optimizeCloudinaryUrl(icon)}
        style={{
          zIndex: '151',
          position: 'absolute',
          top: '25rem',
          left: '50rem',
          height: '15rem',
        }}
        alt='Loading Icon'
      />
    </div>
  );
};

export default Loading;
