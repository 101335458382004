import { useEffect, useState } from 'react';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../Shared/FormatImageLink';

const LaminateHover = ({ colorData, assignClass = false }) => {
  const [topUse, setTopUse] = useState();
  const [rightUse, setRightUse] = useState();
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const wilsonartImage = generalImages?.find((i) => i.publicId === 'Wilsonart_ES-01_nyu0r3');
  const formicaImage = generalImages?.find(
    (i) => i.publicId === 'formica-logo-png-transparent_tlkxeu'
  );
  useEffect(() => {
    if (colorData) {
      if (assignClass) {
        setTopUse(
          document
            ?.getElementById(`${colorData.selectionName}-${assignClass}`)
            ?.getBoundingClientRect().top - 190
        );
        setRightUse(
          document
            ?.getElementById(`${colorData.selectionName}-${assignClass}`)
            ?.getBoundingClientRect().right - 152
        );
      } else {
        setTopUse(
          document?.getElementById(colorData?.selectionName)?.getBoundingClientRect().top - 190
        );
        setRightUse(
          document?.getElementById(colorData?.selectionName)?.getBoundingClientRect().right - 152
        );
      }
    } else {
      setTopUse();
      setRightUse();
    }
  }, [colorData]);
  return (
    <>
      {topUse && rightUse ?
        <div
          role='button'
          aria-label={colorData.selectionName}
          tabIndex={0}
          className='laminate-popup'
          style={{
            position: 'fixed',
            top: `${topUse}px`,
            left: `${rightUse}px`,
            cursor: 'pointer',
          }}
          onClick={() => window.open(colorData.imageLink)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              window.open(colorData.imageLink);
            }
          }}
        >
          <div
            style={{
              backgroundImage: `url(${colorData?.Swatch})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
            className='swatch-div'
            aria-label={`Check out the ${colorData?.selectionName} on the Wilsonart website`}
          ></div>

          <div className='color-info-div'>
            <div> {colorData['Vendor Code']}</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h1>
                {colorData?.selectionName}
                {` (${colorData?.value})`}
                <br></br>
                <span
                  style={{
                    fontSize: '10px',
                    color: 'red',
                  }}
                >
                  {colorData.Type === 'Premium' ? colorData.Type : ''}
                </span>
              </h1>
              {colorData?.brand?.toLowerCase() === 'wilsonart' ?
                <img
                  style={{ height: '1.5rem', width: 'auto' }}
                  src={optimizeCloudinaryUrl(`${wilsonartImage?.imageLink}`)}
                  className='vendor'
                  alt='Wilsonart Engineered Surfaces'
                />
              : <img
                  style={{ height: '3rem', width: 'auto' }}
                  src={optimizeCloudinaryUrl(`${formicaImage?.imageLink}`)}
                  className='vendor'
                  alt='Formica'
                />
              }
            </div>
          </div>
        </div>
      : ''}
    </>
  );
};

export default LaminateHover;
