import React, { useEffect } from 'react';

import Inspire from '../../Components/Dealer Portal/Inspire';
import Partners from '../../Components/Dealer Portal/Partners';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../../Components/Shared/FormatImageLink';

const SpacePlanning = () => {
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const banner = generalImages.find((i) => i.publicId === 'DesignServices_px4s4p')?.imageLink;

  useEffect(() => {
    document.title = `Space Planning- National Public Seating`;
  }, []);

  return (
    <div className='space-planning'>
      <div className='banner'>
        <img
          src={optimizeCloudinaryUrl(banner)}
          alt='Design Services Banner'
        ></img>
      </div>
      <div className='planSpace'>
        <button
          onClick={() => {
            window.open('https://forms.monday.com/forms/964a65debcbfd65aa4a86dd98192a95d?r=use1');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              window.open('https://forms.monday.com/forms/964a65debcbfd65aa4a86dd98192a95d?r=use1');
            }
          }}
        >
          Start Planning
        </button>
      </div>
      <div style={{ height: '50rem' }}></div>
      <Inspire />
      <div
        className='coBranded'
        id='coBranded'
      >
        <button
          onClick={() => {
            window.open('https://forms.monday.com/forms/6182175287cb5cdcc82396a18cd53694');
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              window.open('https://forms.monday.com/forms/6182175287cb5cdcc82396a18cd53694');
            }
          }}
        >
          Submit Request
        </button>
      </div>
      <div style={{ height: '50rem' }}></div>
      <Partners />
    </div>
  );
};

export default SpacePlanning;
