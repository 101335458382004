import { useEffect, useState } from 'react';
import { MdOutlineInfo } from 'react-icons/md';
import { useStageInfo } from '../../context/StageProvider';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../Shared/FormatImageLink';

function ProductList() {
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const {
    buildProducts: step1Products,
    cartProducts,
    stageBuild,
    handleDelete,
    handleDuplicate,
    buildStep,
    steps,
    guardrails,
    skirting,
    stageDollies,
    riserDollies,
    groupByProduct,
    buildDims,
    setHover,
  } = useStageInfo();
  const editableQty = buildStep === 1;

  const [buildProducts, setBuildProducts] = useState();
  const [capacity, setCapacity] = useState();

  useEffect(() => {
    if (!cartProducts) {
      setBuildProducts(step1Products);
    } else {
      const holderArray = [...cartProducts];
      if (steps.length) {
        holderArray.push(...groupByProduct(steps));
      }
      if (guardrails.length && guardrails[0].product) {
        holderArray.push(
          ...groupByProduct(guardrails.filter((g) => !steps.find((s) => s.id === g.id)))
        );
      }
      if (skirting.length) {
        holderArray.push(
          ...groupByProduct(skirting.filter((g) => !steps.find((s) => s.id === g.id)))
        );
      }
      if (stageDollies) {
        holderArray.push(stageDollies);
      }
      if (riserDollies) {
        holderArray.push(riserDollies);
      }
      setBuildProducts(holderArray);
    }
  }, [cartProducts, steps, guardrails, skirting, stageDollies, riserDollies]);

  useEffect(() => {
    let totalSeatedCapacity = 0;
    let totalSeatedBandCapacity = 0;
    let totalStandingCapacity = 0;
    stageBuild.forEach((i) => {
      switch (i.capacityType) {
        case 'Seated':
          totalSeatedCapacity = totalSeatedCapacity + i.capacity;
          break;
        case 'Seated Band':
          totalSeatedBandCapacity = totalSeatedBandCapacity + i.capacity;
          break;
        case 'Standing':
          totalStandingCapacity = totalStandingCapacity + i.capacity;
          break;
      }
    });
    setCapacity({
      Seated: totalSeatedCapacity,
      SeatedBand: totalSeatedBandCapacity,
      Standing: totalStandingCapacity,
    });
  }, [stageBuild]);

  return (
    <div>
      <span className='stage-product-list'>
        <div className='stage-product stage-product-header'>
          <div>Model #</div>
          <div>Product Description</div>
          <div>Quantity</div>
        </div>
        <div className='underline'></div>
        <div className='stage-products-wrapper'>
          {buildProducts?.length ?
            <>
              {buildProducts.map((product) => (
                <div
                  className='stage-product'
                  key={product.modelNum}
                >
                  <div
                    className='stage-img'
                    role='button'
                    aria-label={product.modelNum}
                    tabIndex={0}
                    onClick={() => {
                      window.open(
                        `/products/${product.product.productInfo.Series}?${Object.keys(
                          product.product.configOptions
                        )
                          ?.map(
                            (key) =>
                              `${encodeURIComponent(key)}=${encodeURIComponent(
                                product.product.configOptions[key].selectionName
                              )}`
                          )
                          ?.join('&')}`,
                        '_blank'
                      );
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        window.open(
                          `/products/${product.product.productInfo.Series}?${Object.keys(
                            product.product.configOptions
                          )
                            ?.map(
                              (key) =>
                                `${encodeURIComponent(key)}=${encodeURIComponent(
                                  product.product.configOptions[key].selectionName
                                )}`
                            )
                            ?.join('&')}`,
                          '_blank'
                        );
                      }
                    }}
                  >
                    <img
                      src={
                        optimizeCloudinaryUrl(product.product.cdnLinks[0]?.Image) ||
                        optimizeCloudinaryUrl(
                          generalImages.find((i) => i.publicId === 'image-coming-soon_lzhzdk')
                            ?.imageLink
                        )
                      }
                      alt={product.modelNum}
                    />
                    <h4>{product.modelNum}</h4>
                  </div>
                  <h4>{product.product.productInfo.ShortDescription}</h4>
                  {editableQty ?
                    <div className='cart-quantity stage-product-quantity'>
                      <div
                        role='button'
                        aria-label='Minus'
                        tabIndex={0}
                        className='minus'
                        onClick={() => {
                          handleDelete(stageBuild.find((i) => i.modelNum === product.modelNum).uid);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleDelete(
                              stageBuild.find((i) => i.modelNum === product.modelNum).uid
                            );
                          }
                        }}
                      >
                        -
                      </div>
                      <div className='quantity-input'>{product.quantity}</div>
                      <div
                        role='button'
                        aria-label='Plus'
                        tabIndex={0}
                        className='plus'
                        onClick={() => {
                          handleDuplicate(
                            stageBuild.find((i) => i.modelNum === product.modelNum).uid
                          );
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            stageBuild.find((i) => i.modelNum === product.modelNum).uid;
                          }
                        }}
                      >
                        +
                      </div>
                    </div>
                  : <h4>QTY: {product.quantity}</h4>}
                </div>
              ))}
              {capacity && buildDims && (
                <div className='product-list-totals'>
                  <div className='build-total-capacity'>
                    <div className='capacity-title'>
                      Build Dimensions
                      <MdOutlineInfo
                        onMouseEnter={(event) =>
                          setHover({
                            text: 'Displayed dimensions are an estimate and may not reflect exact measurements.',
                            color: 'darkgrey',
                            top: event.clientY,
                            left: event.clientX,
                          })
                        }
                      />
                    </div>
                    <div>
                      {Math.floor(parseFloat(buildDims.right.x - buildDims.left.x).toFixed(1) / 12)}
                      '
                      {Math.round(parseFloat(buildDims.right.x - buildDims.left.x).toFixed(1) % 12)}
                      " x
                      {Math.floor(parseFloat(buildDims.bottom.y - buildDims.top.y).toFixed(1) / 12)}
                      '
                      {Math.round(parseFloat(buildDims.bottom.y - buildDims.top.y).toFixed(1) % 12)}
                      "
                    </div>
                  </div>
                  <div className='build-total-capacity'>
                    <div className='capacity-title'>Suggested Capacity</div>
                    {Object.keys(capacity).map((c, index) => {
                      if (capacity[c]) {
                        return (
                          <div key={index}>{`${c.replace(/([A-Z])/g, ' $1').trim()}: ${
                            capacity[c]
                          }`}</div>
                        );
                      }
                    })}
                  </div>
                </div>
              )}
            </>
          : <h3>Items in your build will be displayed here.</h3>}
        </div>
      </span>
    </div>
  );
}

export default ProductList;
