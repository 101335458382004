import { useNavigate } from 'react-router-dom';
import '../../CSS/EnvironmentsSlider.css';
import { AdvancedImage } from '@cloudinary/react';
import { fill } from '@cloudinary/url-gen/actions/resize';
import FormatImageLink from '../Shared/FormatImageLink';

const EnvironmentCard = ({ src, title }) => {
  const navigate = useNavigate();
  const { decodeAndFormat } = FormatImageLink();

  return (
    <div
      role='button'
      aria-label={title[0]}
      tabIndex={0}
      className='env-card'
      onClick={() => {
        navigate(`/environments?filter=${title[0]}`);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          navigate(`/environments?filter=${title[0]}`);
        }
      }}
    >
      <AdvancedImage
        cldImg={decodeAndFormat(src)?.resize(fill().width(386).height(386))}
        alt={`Check out the ${title[0]}`}
      />
      <div className='h3'>{title[0]}</div>
    </div>
  );
};

export default EnvironmentCard;
