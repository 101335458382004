import { AdvancedImage } from '@cloudinary/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductInfo } from '../../ReduxSlices/productState';
import FormatImageLink from '../Shared/FormatImageLink';

const AddOnCube = ({ addOn, availableOptions }) => {
  const { optionsSelected, accessoriesSelected, product } = useSelector(
    (state) => state.productState.value
  );
  const dispatch = useDispatch();
  const { decodeAndFormat } = FormatImageLink();
  const [hover, setHover] = useState(false);

  function clicked() {
    if (addOn.accessorySkus) {
      if (accessoriesSelected.find((a) => a.name === addOn.name)) {
        dispatch(
          updateProductInfo({
            accessoriesSelected: accessoriesSelected.filter((a) => a.name !== addOn.name),
          })
        );
      } else {
        dispatch(
          updateProductInfo({
            accessoriesSelected: [...accessoriesSelected, addOn],
          })
        );
      }
    } else if (optionsSelected[addOn.name]) {
      var holderObj = { ...optionsSelected };
      delete holderObj[addOn.name];
      dispatch(updateProductInfo({ optionsSelected: holderObj }));
    } else {
      dispatch(
        updateProductInfo({
          optionsSelected: {
            ...optionsSelected,
            [addOn.name]: true,
          },
        })
      );
    }
  }
  return (
    <div
      role='button'
      aria-label='Add on'
      tabIndex={0}
      onMouseEnter={() => (addOn.name === 'Casters' ? setHover(addOn.name) : setHover(false))}
      onMouseLeave={() => setHover(false)}
      className={`add-on-cube  
      
      ${!availableOptions?.includes(addOn.name) ? 'unavailable' : ''}
       `}
      style={{
        borderColor: `${
          optionsSelected[addOn.name] || accessoriesSelected.find((a) => a.name === addOn.name) ?
            'var(--darkblue)'
          : ''
        }`,
        cursor: availableOptions?.includes(addOn.name) ? 'pointer' : 'not-allowed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={() => {
        if (availableOptions?.includes(addOn.name)) {
          clicked();
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          if (availableOptions?.includes(addOn.name)) {
            clicked();
          }
        }
      }}
    >
      <AdvancedImage
        cldImg={decodeAndFormat(
          addOn?.imageLink ||
            addOn?.accessorySkus?.find((i) => product.modelNum.includes(i.accessoryFor))
              ?.imageLink ||
            addOn?.accessorySkus[0]?.imageLink
        )}
        style={{
          cursor: availableOptions?.includes(addOn.name) ? 'pointer' : 'not-allowed',
          opacity: availableOptions?.includes(addOn.name) ? '1' : '.35',
        }}
      />

      {hover === 'Casters' ?
        <div
          style={{
            position: 'absolute',
            background: '#00435B',
            padding: '.5rem',
            fontSize: '10px',
            color: 'white',
            fontWeight: '300',
            textTransform: 'none',
            width: '120px',
            marginTop: '-10rem',
            borderRadius: '.5rem',
            boxShadow: '0 0 5px black',
            textAlign: 'center',
            zIndex: 7,
          }}
        >
          Casters will raise the table's height by 3.5"
        </div>
      : ''}
    </div>
  );
};

export default AddOnCube;
