import { AdvancedImage } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { useEffect, useState } from 'react';
import { BsFillFileEarmarkTextFill } from 'react-icons/bs';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { createSearchParams } from 'react-router-dom';
import axios from '../../api/axios';
import { useCart } from '../../context/CartContext';
import useAuth from '../../hooks/useAuth';
import FormatImageLink from '../Shared/FormatImageLink';
const OrderDetails = ({ items }) => {
  const [open, setOpen] = useState(false);
  const { decodeAndFormat, optimizeCloudinaryUrl } = FormatImageLink();
  const [statusIcons, setStatusIcons] = useState();
  const { auth } = useAuth();
  const { notificationData } = useCart();
  async function getStatusIcons() {
    await axios
      .get(`products/statusIcons`)
      .then((response) => {
        setStatusIcons(response.data);
      })
      .catch((error) => {
        console.error('Error fetching status icons:', error);
      });
  }
  useEffect(() => {
    getStatusIcons();
  }, []);

  async function handleClick(item) {
    const series = encodeURIComponent(item?.series?.config_group || item?.series?.series);
    const configs = item?.configOptions ? createSearchParams(item?.configOptions) : '';
    window.open(`https://www.nationalpublicseating.com/products/${series}?${configs}`);
  }

  return (
    <div className={`po-details  ${!open ? 'po-details-closed' : ''}`}>
      <div
        role='button'
        aria-label='Order Details'
        tabIndex={0}
        className='po-header'
        onClick={() => {
          setOpen(!open);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setOpen(!open);
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <BsFillFileEarmarkTextFill /> <div style={{ marginLeft: '1.5rem' }}>Order Details</div>
        </div>

        {/* <div>Detailed Order</div>
        <div>
          Freight Quote: <span>${order.freight}</span>
        </div>
        <div>
          Total: <span>${order.orderTotal}</span>
        </div> */}

        {open ?
          <FiChevronUp
            className='open'
            onClick={() => {
              setOpen(false);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOpen(false);
              }
            }}
          />
        : <FiChevronDown
            className='open'
            onClick={() => {
              setOpen(true);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOpen(true);
              }
            }}
          />
        }
      </div>
      {open ?
        <div className={`po-details-list `}>
          <div className='row'>
            <div className='image-div'>IMAGE</div>
            <div className='sku-title-div'>SKU</div>
            <div className='description-div'>DESCRIPTION</div>
            <div className='quantity-div'>QUANTITY</div>

            {(
              auth?.accounts
                .find((account) => account.last_login === 1)
                ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) &&
              !notificationData?.some((i) => i.Path === 'pricing')
            ) ?
              <div className='unit-price-div'>UNIT PRICE</div>
            : null}
            {(
              auth?.accounts
                .find((account) => account.last_login === 1)
                ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) &&
              !notificationData?.some((i) => i.Path === 'pricing')
            ) ?
              <div className='amount-div'>AMOUNT</div>
            : null}
          </div>
          {items?.map((i, index) => {
            return (
              <div
                key={index}
                className='row'
              >
                <div
                  className='image-div'
                  style={{ height: '50px' }}
                >
                  <img
                    onClick={() => handleClick(i)}
                    src={optimizeCloudinaryUrl(`${i?.imageLink}`)}
                    style={{
                      cursor: 'pointer',
                      maxHeight: '100%',
                      maxWidth: '100%',
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleClick(i);
                      }
                    }}
                    alt={i.sku}
                  />
                  {i.productStatus !== 'A' && statusIcons && (
                    <AdvancedImage
                      style={{
                        position: 'absolute',
                        marginTop: '-11px',
                        marginLeft: '-58px',
                        cursor: 'pointer',
                      }}
                      cldImg={decodeAndFormat(
                        statusIcons?.find((j) => j.status === i.productStatus)?.icon_link
                      )?.resize(scale().width(35))}
                    />
                  )}
                </div>
                <div
                  role='button'
                  aria-label={`Go to ${i.sku}`}
                  tabIndex={0}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleClick(i)}
                  className='sku-title-div'
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleClick(i);
                    }
                  }}
                >
                  {i.sku}
                </div>
                <div
                  className='description-div'
                  style={{ textAlign: 'left' }}
                >
                  {i?.description}
                </div>
                <div className='quantity-div'>{Math.floor(i.qty)}</div>
                {(
                  auth?.accounts
                    .find((account) => account.last_login === 1)
                    ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) &&
                  !notificationData?.some((i) => i.Path === 'pricing')
                ) ?
                  <div className='unit-price-div'>
                    {Number(i.unitPrice).toLocaleString(undefined, {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                : null}
                {(
                  auth?.accounts
                    .find((account) => account.last_login === 1)
                    ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) &&
                  !notificationData?.some((i) => i.Path === 'pricing')
                ) ?
                  <div className='amount-div'>
                    {Number(i.unitPrice * i.qty).toLocaleString(undefined, {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                : null}
              </div>
            );
          })}
        </div>
      : ''}
    </div>
  );
};

export default OrderDetails;
