import markerSDK from '@marker.io/browser';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../src/CSS/App.css';
import '../src/CSS/MobileApp/M_App.css';
import { CartProvider } from './context/CartContext';
import { ContentProvider } from './context/ContentProvider';
import { GeneralImageProvider } from './context/GeneralImageProvider';
import useAuth from './hooks/useAuth';
import RouteDirectory from './RouteDirectory';
import Hotjar from '@hotjar/browser';

function App() {

  const { auth } = useAuth();
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;
  const siteId = import.meta.env.VITE_HOTJAR_SITE_ID;
  const hotjarVersion = import.meta.env.VITE_HOTJAR_VERSION;

  // Detect when the user is tabbing through the website
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Tab') {
      document.body.classList.add('keyboard-navigation');
    }
  });

  // Remove the class when the user starts clicking
  document.addEventListener('mousedown', () => {
    document.body.classList.remove('keyboard-navigation');
  });

  async function loadWidget() {
    if (auth?.roles?.includes(3500)) {
      // eslint-disable-next-line no-unused-vars
      const widget = await markerSDK.loadWidget({
        project: '64778327517aa570924847af',
      });
    }
  }

  function editMetaViewPort() {
    document.getElementsByTagName('meta').viewport.content = 'width=540';
  }

  useEffect(() => {
    if (mobileScreen) {
      editMetaViewPort();
    }
  }, []);

  useEffect(() => {
    loadWidget();
  }, [auth?.roles]);

  useEffect(() => {
    Hotjar.init(siteId, hotjarVersion);
  }, []);

  useEffect(() => {
    const navigationEntries = performance.getEntriesByType('navigation');
    const isReload = navigationEntries[0]?.type === 'reload';

    const handleBeforeUnload = () => {
      if (isReload) {
        const payload = {
          action: 'reload',
          timestamp: new Date().toISOString(),
          pathname: window.location.pathname,
        };

        navigator.sendBeacon(`${apiAddress}/log/logReload`, JSON.stringify(payload));
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <div className='App'>
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css2?family=Poppins'
        />
        <link
          href='http://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900'
          rel='stylesheet'
          type='text/css'
        />
        <CartProvider>
          <ContentProvider>
            <GeneralImageProvider>
              <RouteDirectory />
            </GeneralImageProvider>
          </ContentProvider>
        </CartProvider>
      </div>
    </>
  );
}

export default App;
