import React from 'react';
import { AdvancedImage } from '@cloudinary/react';
import { removeBackground } from '@cloudinary/url-gen/actions/effect';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { useSelector } from 'react-redux';
import '../../CSS/ProductBanner.css';
import '../../CSS/MobileApp/M_ProductBanner.css';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from './FormatImageLink';

const ProductBanner = ({ text, image }) => {
  const mobileScreen = useSelector((state) => state.isMobile.value);
  const { generalImages } = useGeneralImages();
  const { decodeAndFormat, optimizeCloudinaryUrl } = FormatImageLink();
  const banner =
    mobileScreen.isMobile ?
      generalImages.find((i) => i.publicId === 'product_category_banner')?.imageLink
    : generalImages.find((i) => i.publicId === 'GeneralProducts_zhjzrl')?.imageLink;

  return (
    <div>
      <img
        src={optimizeCloudinaryUrl(banner)}
        className='banner-background'
        alt='Background Banner'
      />
      <div className='banner-div'>
        <div className='banner-text'>{text}</div>
        {image ?
          <AdvancedImage
            cldImg={decodeAndFormat(image)
              ?.resize(scale().width(500))
              ?.effect(removeBackground('fineEdges'))
              ?.format('png')}
            className='banner-img'
            alt='Background Banner'
          />
        : ''}
      </div>
    </div>
  );
};

export default ProductBanner;
