import { useState } from 'react';
import FormatImageLink from '../Shared/FormatImageLink';

const Value = ({ data }) => {
  const [open, setOpen] = useState(false);
  const { optimizeCloudinaryUrl } = FormatImageLink();
  return (
    <div className={`value ${open ? 'value-open' : ''}`}>
      <div className='image-div'>
        <img
          src={optimizeCloudinaryUrl(data.image)}
          alt={data.title}
        />
      </div>
      <h1>{data.title}</h1>
      <div className='underline'></div>
      <p>{open ? data.full_data : data.data}</p>
      <h3
        role='button'
        aria-label={open ? 'Read Less' : 'Read More'}
        tabIndex={0}
        onClick={() => {
          setOpen(!open);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setOpen(!open);
          }
        }}
      >
        {open ? 'Read Less' : 'Read More'}
      </h3>
    </div>
  );
};

export default Value;
