/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useStageInfo } from '../../context/StageProvider';
import { HiCheckCircle } from 'react-icons/hi';
import { RxMinusCircled } from 'react-icons/rx';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { FaMagic } from 'react-icons/fa';
import { enlargeBuild } from './StageFunctions';
import FormatImageLink from '../Shared/FormatImageLink';

function GenerateCustomStage() {
  const {
    packages,
    setConfigure,
    setSelected,
    setStageBuild,
    setUniqueIdTracker,
    uniqueIdTracker,
    resetZoom,
    setToggle,
    areaDimensions,
    setAreaDimensions,
  } = useStageInfo();
  const customizations = [
    {
      id: 0,
      name: 'Area Size',
      inputOptions: [
        { id: 0, name: 'Width' },
        { id: 1, name: 'Length' },
      ],
    },
    {
      id: 1,
      name: 'Capacity',
      type: 'selectOne',
      inputOptions: [{ id: 0, name: 'Capacity' }],
      options: [
        {
          id: 0,
          name: 'Seated',
          img: 'https://res.cloudinary.com/da3rom333/image/upload/v1725893370/Website%20Assets/Stage%20Configurator/Icon_SC_Seated_fdbj1s.svg',
          hover: `Seated Risers    36" deep`,
          combinesWith: {
            name: 'Shape',
            values: ['Rectangle', 'Bow', 'Ring', 'Oval'],
          },
        },
        {
          id: 1,
          name: 'Seated Band',
          img: 'https://res.cloudinary.com/da3rom333/image/upload/v1725893376/Website%20Assets/Stage%20Configurator/Icon_SC_Seated_Band_l8vxxo.svg',
          hover: `Seated Band Risers    48" deep`,
          combinesWith: {
            name: 'Shape',
            values: ['Rectangle', 'Rectangle Arch', 'Bow', 'Ring', 'Oval'],
          },
        },
        {
          id: 2,
          name: 'Standing',
          img: 'https://res.cloudinary.com/da3rom333/image/upload/v1725893363/Website%20Assets/Stage%20Configurator/Icon_SC_Standing_ipkvg1.svg',
          hover: `Standing Risers    18" deep`,
          combinesWith: { name: 'Shape', values: ['Rounded Arch'] },
        },
      ],
    },
    {
      id: 2,
      name: 'Levels',
      type: 'selectMultiple',
      options: [
        {
          id: 0,
          name: 1,
          img: 'https://res.cloudinary.com/da3rom333/image/upload/v1686135427/Icons/ProductPageIcons/Icons%20Version%202/Selections/Height_8_dsz025.svg',
        },
        {
          id: 1,
          name: 2,
          img: 'https://res.cloudinary.com/da3rom333/image/upload/v1684764417/Icons/ProductPageIcons/Icons%20Version%202/Selections/Height_16_yrkrzj.svg',
        },
        {
          id: 2,
          name: 3,
          img: 'https://res.cloudinary.com/da3rom333/image/upload/v1684764417/Icons/ProductPageIcons/Icons%20Version%202/Selections/Height_24_x7x55x.svg',
        },
        {
          id: 3,
          name: 4,
          img: 'https://res.cloudinary.com/da3rom333/image/upload/v1681934584/Icons/ProductPageIcons/Icons%20Version%202/Selections/Height_32_rfpfmu.webp',
        },
      ],
    },
    {
      id: 3,
      name: 'Shape',
      type: 'selectOne',
      options: [
        {
          id: 0,
          name: 'Rectangle',
          img: 'https://res.cloudinary.com/da3rom333/image/upload/v1725905861/Website%20Assets/Stage%20Configurator/Icon_Custom_Rectangle_im5ffg.svg',
          combinesWith: { name: 'Capacity', values: ['Seated', 'Seated Band'] },
        },
        {
          id: 1,
          name: 'Rectangle Arch',
          img: 'https://res.cloudinary.com/da3rom333/image/upload/v1725905833/Website%20Assets/Stage%20Configurator/Icon_Custom_Rectangular_Arch_zwmwou.svg',
          combinesWith: { name: 'Capacity', values: ['Seated Band'] },
        },
        {
          id: 2,
          name: 'Rounded Arch',
          img: 'https://res.cloudinary.com/da3rom333/image/upload/v1725905870/Website%20Assets/Stage%20Configurator/Icon_Custom_Rounded_Arch_ups96d.svg',
          combinesWith: { name: 'Capacity', values: ['Standing'] },
        },
        {
          id: 3,
          name: 'Bow',
          img: 'https://res.cloudinary.com/da3rom333/image/upload/v1725905858/Website%20Assets/Stage%20Configurator/Icon_Custom_Bow_Set_Up_evoctr.svg',
          combinesWith: { name: 'Capacity', values: ['Seated', 'Seated Band'] },
        },
        {
          id: 4,
          name: 'Ring',
          img: 'https://res.cloudinary.com/da3rom333/image/upload/v1725905865/Website%20Assets/Stage%20Configurator/Icon_Custom_Ring_Set_Up_evfjhu.svg',
          combinesWith: { name: 'Capacity', values: ['Seated', 'Seated Band'] },
        },
        {
          id: 5,
          name: 'Oval',
          img: 'https://res.cloudinary.com/da3rom333/image/upload/v1725905845/Website%20Assets/Stage%20Configurator/Icon_Custom_Oval_Set_Up_iozm9t.svg',
          combinesWith: { name: 'Capacity', values: ['Seated', 'Seated Band'] },
        },
      ],
    },
  ];
  const [categorySelected, setCategorySelected] = useState();
  const [selections, setSelections] = useState({});
  const [noBuildFound, setNoBuildFound] = useState();
  const [iconHover, setIconHover] = useState();
  const { optimizeCloudinaryUrl } = FormatImageLink();

  function getSelections(category) {
    switch (category.name) {
      case 'Area Size':
        return `${
          selections.WidthInput ?
            `Width: ${Math.round((selections.WidthInput / 12) * 100) / 100}'`
          : 'Insert Width'
        }; ${
          selections.LengthInput ?
            `Length: ${Math.round((selections.LengthInput / 12) * 100) / 100}'`
          : 'Insert Length'
        }`;
      case 'Capacity':
        return `${
          selections.CapacityInput > 0 ? `${selections.CapacityInput} people` : 'Insert Capacity'
        }${
          selections.Capacity ? `; ${selections.Capacity[0]}`
          : selections.CapacityInput > 0 ? '; Select Type'
          : ''
        }`;
      default:
        return selections[category.name]?.length > 0 ?
            selections[category.name].sort((a, b) => parseInt(a) - parseInt(b)).join('; ')
          : `Select ${category.name}`;
    }
  }

  function handleClick(category, option) {
    if (
      Object.entries(selections).find(
        ([key, val]) => key === category.name && val.find((v) => v === option.name)
      )
    ) {
      if (category.type === 'selectMultiple') {
        const holderObj = { ...selections };
        holderObj[category.name] = holderObj[category.name].filter((o) => o !== option.name);
        setSelections(holderObj);
      } else {
        const holderObj = { ...selections };
        delete holderObj[category.name];
        setSelections(holderObj);
        setIconHover(option?.id);
      }
    } else {
      if (category.type === 'selectMultiple' && selections[category.name]) {
        setSelections({
          ...selections,
          [category.name]: [...selections[category.name], option.name],
        });
      } else {
        setSelections({ ...selections, [category.name]: [option.name] });
        setIconHover();
      }
    }
  }

  function handleGenerate() {
    //find the package in the prebuilt packages that best matches the selected customization options
    setNoBuildFound();
    const generatedPackages = findBestPackages()?.sort((a, b) => {
      if (selections.LengthInput && parseInt(selections.LengthInput) > 0) {
        const diffA = parseInt(selections.LengthInput) - parseInt(a.length);
        const diffB = parseInt(selections.LengthInput) - parseInt(b.length);
        return diffA - diffB;
      } else if (selections.WidthInput && parseInt(selections.WidthInput) > 0) {
        const diffA = parseInt(selections.WidthInput) - parseInt(a.width);
        const diffB = parseInt(selections.WidthInput) - parseInt(b.width);
        return diffA - diffB;
      } else if (selections.CapacityInput && parseInt(selections.CapacityInput) > 0) {
        const diffA = parseInt(a.capacity) - parseInt(selections.CapacityInput);
        const diffB = parseInt(b.capacity) - parseInt(selections.CapacityInput);
        return diffA - diffB;
      }
    });
    if (generatedPackages?.length) {
      setConfigure();
      let uidTracker = 0;
      setSelected();
      setStageBuild([
        ...generatedPackages[0].items.map((item) => {
          return {
            ...item,
            uid: uidTracker++,
          };
        }),
      ]);
      setUniqueIdTracker(uniqueIdTracker + generatedPackages[0].items.length);
      resetZoom(generatedPackages[0].items);
    }
  }

  function findBestPackages() {
    //find the packages that meets all the customization requirements
    let filteredPackages = [...packages];
    //capacity type
    if (selections.Capacity) {
      filteredPackages = filteredPackages?.filter((p) => p.capacityType === selections.Capacity[0]);
    }
    // shape
    if (selections.Shape) {
      filteredPackages = filteredPackages?.filter((p) => p.shape === selections.Shape[0]);
    }
    //levels
    if (selections.Levels?.length) {
      const packagesHolderArr = [...filteredPackages];
      filteredPackages = packagesHolderArr
        ?.filter((p) => p.items.every((i) => selections.Levels.includes(i.level)))
        ?.filter((p) => selections.Levels.every((level) => p.items.find((i) => i.level === level)));
      if (!filteredPackages.length) {
        filteredPackages = packagesHolderArr?.filter((p) =>
          selections.Levels.every((level) => p.items.find((i) => i.level === level))
        );
      }
      if (!filteredPackages.length) {
        return setNoBuildFound('level selections');
      }
    }

    //size
    if (selections.WidthInput && parseInt(selections.WidthInput) > 0) {
      if (selections.WidthInput > 1152) {
        return setNoBuildFound('area width');
      }
      filteredPackages = filteredPackages?.filter(
        (p) => parseInt(p.width) <= Math.floor(Number(selections.WidthInput))
      );
      if (!filteredPackages.length) {
        return setNoBuildFound('area width');
      }
    }
    if (selections.LengthInput && parseInt(selections.LengthInput) > 0) {
      if (selections.LengthInput > 1656) {
        return setNoBuildFound('area length');
      }
      filteredPackages = filteredPackages?.filter(
        (p) =>
          //if package is a rectangle, leave space for steps on the sides
          (p.shape === 'Rectangle' &&
            parseInt(p.length) + (p.items[0].level - 1) * 28 <=
              Math.floor(Number(selections.LengthInput))) ||
          (p.shape !== 'Rectangle' &&
            parseInt(p.length) <= Math.floor(Number(selections.LengthInput)))
      );
      //handle length larger than longest package
      filteredPackages = filteredPackages.map((build) => {
        if (build.items.some((item) => item.middle === 'true')) {
          const newBuild = enlargeBuild(build.items, {
            length:
              build.shape === 'Rectangle' ?
                Math.floor(Number(selections.LengthInput)) - (build.items[0].level - 1) * 28
              : Math.floor(Number(selections.LengthInput)),
          });
          return {
            ...build,
            items: newBuild,
            enlarged: build.items.length !== newBuild.length, //check if length is diff
          };
        }
        return build;
      });
      if (!filteredPackages.length) {
        return setNoBuildFound('area length');
      }
    }
    //capacity
    if (selections.CapacityInput && parseInt(selections.CapacityInput) > 0) {
      const holderArray = [...filteredPackages];
      filteredPackages = filteredPackages?.filter(
        (p) => parseInt(p.capacity) >= Math.ceil(Number(selections.CapacityInput))
      );
      //handle capacity greater than largest package
      if (!filteredPackages.length) {
        const expandableBuilds = holderArray.filter((build) =>
          build.items.some((item) => item.middle === 'true')
        );
        filteredPackages = expandableBuilds.map((build) => {
          return {
            ...build,
            items: enlargeBuild(build.items, {
              capacity: Math.ceil(Number(selections.CapacityInput)),
              length:
                selections.LengthInput > 0 ?
                  build.shape === 'Rectangle' ?
                    Math.floor(Number(selections.LengthInput)) - (build.items[0].level - 1) * 28
                  : Math.floor(Number(selections.LengthInput))
                : null,
            }),
          };
        });
        filteredPackages = filteredPackages.filter(
          (p) =>
            p.items.reduce((total, i) => total + i.capacity, 0) >
            Math.ceil(Number(selections.CapacityInput))
        );
        if (!filteredPackages.length) {
          return setNoBuildFound('capacity');
        }
      }
    }
    return filteredPackages;
  }

  useEffect(() => {
    if (noBuildFound) {
      setCategorySelected();
    }
  }, [noBuildFound]);

  useEffect(() => {
    if (selections?.WidthInput || selections?.LengthInput) {
      setToggle(true);
      setAreaDimensions({ width: selections.WidthInput, length: selections.LengthInput });
    }
  }, [selections?.WidthInput, selections?.LengthInput]);

  useEffect(() => {
    if (
      (areaDimensions.width !== selections?.WidthInput ||
        areaDimensions.length !== selections?.LengthInput) &&
      categorySelected?.name === 'Area Size'
    ) {
      setSelections({
        ...selections,
        WidthInput: areaDimensions.width,
        LengthInput: areaDimensions.length,
      });
    }
  }, [areaDimensions, categorySelected]);

  return (
    <div className='generate-custom-wrapper'>
      {customizations?.map((category) => (
        <div key={category.id}>
          <div
            role='button'
            aria-label={category.name}
            tabIndex={0}
            className='custom-category'
            onClick={() => {
              if (categorySelected?.id === category.id) {
                setCategorySelected();
              } else {
                setCategorySelected(category);
              }
            }}
            onKeyDown={(e) => {
              if (categorySelected?.id === category.id) {
                setCategorySelected();
              } else {
                setCategorySelected(category);
              }
            }}
          >
            <span className='custom-category-wrapper-1'>
              {(
                ((category.options && selections[category.name]?.length) || !category.options) &&
                ((category.inputOptions &&
                  category.inputOptions.every((optn) => selections[`${optn.name}Input`] > 0)) ||
                  !category.inputOptions)
              ) ?
                <HiCheckCircle className='check-circle' />
              : <RxMinusCircled className='minus-circle' />}
              <h3 className='config-parent-name'>{category.name}</h3>
            </span>
            <span className='custom-category-wrapper-2'>
              <div
                className={
                  (
                    ((category.options && selections[category.name]?.length) ||
                      !category.options) &&
                    ((category.inputOptions &&
                      category.inputOptions.every((optn) => selections[`${optn.name}Input`] > 0)) ||
                      !category.inputOptions)
                  ) ?
                    'custom-values'
                  : 'select-custom'
                }
              >
                {getSelections(category)}
              </div>
              {categorySelected?.id === category.id ?
                <BsChevronUp className='down-arrow' />
              : <BsChevronDown className='down-arrow' />}
            </span>
          </div>
          {categorySelected?.id === category.id && (
            <div>
              {category.inputOptions?.map((o) => (
                <div
                  className='sizing-option'
                  key={o.id}
                >
                  <span>
                    Insert {o.name}
                    {category.name === 'Area Size' && ` (')`}:
                  </span>
                  <input
                    type='number'
                    min={0}
                    className='space-dimension'
                    value={
                      selections[`${o.name}Input`] ?
                        o.name === 'Capacity' ?
                          selections[`${o.name}Input`]
                        : Math.round((selections[`${o.name}Input`] / 12) * 100) / 100
                      : ''
                    }
                    placeholder='0'
                    onChange={(e) =>
                      setSelections({
                        ...selections,
                        [`${o.name}Input`]:
                          o.name === 'Capacity' ? e.target.value : e.target.value * 12,
                      })
                    }
                  ></input>
                </div>
              ))}
              <div className='custom-options'>
                {category.options?.map((o) => (
                  <div
                    role='button'
                    aria-label={category.options.name}
                    tabIndex={0}
                    className={`option-cube ${
                      (
                        selections[o.combinesWith?.name]?.some(
                          (s) => !o.combinesWith.values.includes(s)
                        )
                      ) ?
                        'disable-option'
                      : (
                        Object.entries(selections).find(
                          ([key, val]) => key === category.name && val.find((v) => v === o.name)
                        )
                      ) ?
                        'option-cube-clicked'
                      : ''
                    } `}
                    onClick={() => {
                      if (
                        !selections[o.combinesWith?.name]?.some(
                          (s) => !o.combinesWith.values.includes(s)
                        )
                      ) {
                        handleClick(category, o);
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        if (
                          !selections[o.combinesWith?.name]?.some(
                            (s) => !o.combinesWith.values.includes(s)
                          )
                        ) {
                          handleClick(category, o);
                        }
                      }
                    }}
                    onMouseEnter={() => setIconHover(o.id)}
                    onMouseLeave={() => setIconHover()}
                    key={o.id}
                  >
                    {iconHover === o.id && o.hover ?
                      <span className='icon-hover-info'>{o.hover}</span>
                    : <img
                        src={optimizeCloudinaryUrl(o.img)}
                        alt={o.name}
                      ></img>
                    }
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
      <div
        role='button'
        aria-label='Generate'
        tabIndex={0}
        className='generate-build-button custom-category'
        onClick={handleGenerate}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleGenerate();
          }
        }}
      >
        <FaMagic />
        <h5>GENERATE</h5>
      </div>
      {noBuildFound && (
        <span className='no-build-found-msg'>
          Sorry! We could not generate a build with all your specifications. Try changing the{' '}
          {noBuildFound} and regenerating.
          <span
            role='button'
            aria-label='Clear Selection'
            tabIndex={0}
            className='clear-selections'
            onClick={() => {
              setSelections({});
              setNoBuildFound();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setSelections({});
                setNoBuildFound();
              }
            }}
          >
            Clear Selections
          </span>
        </span>
      )}
    </div>
  );
}

export default GenerateCustomStage;
