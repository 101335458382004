import React, { useState } from 'react';
import '../../CSS/MediaCenter.css';
import '../../CSS/MobileApp/M_MediaCenter.css';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../Shared/FormatImageLink';

const MediaCenterCube = ({ data, clickHandler }) => {
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const videos = generalImages.find((i) => i.publicId === 'Videos_hdne4c')?.imageLink;
  const nps = generalImages.find((i) => i.publicId === 'Nps_rbfit5')?.imageLink;
  const gsa = generalImages.find((i) => i.publicId === 'Gsa_ewbgjs')?.imageLink;
  const downloads = generalImages.find((i) => i.publicId === 'Downloads_l9bsqz')?.imageLink;
  const brochures = generalImages.find((i) => i.publicId === 'Brochures_q0qqkt')?.imageLink;

  function getImg() {
    switch (data.title) {
      case 'Media Downloads':
        return downloads;
      case 'Digital Catalog':
        return nps;
      case 'GSA Catalog':
        return gsa;
      case 'Brochures':
        return brochures;
      case 'Promo Videos':
        return videos;
      default:
        return null;
    }
  }
  const [hover, setHover] = useState(false);
  return (
    <div
      role='button'
      tabIndex={0}
      className='mediaCenterCube'
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        clickHandler(data);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          clickHandler(data);
        }
      }}
    >
      <img
        src={optimizeCloudinaryUrl(getImg())}
        alt='media cube'
      ></img>
      <div className='underline'></div>
      <button
        style={{
          background: hover ? 'var(--lightgreen)' : 'white',
          fontWeight: hover ? '700' : '600',
        }}
      >
        {data.title}
      </button>
    </div>
  );
};

export default MediaCenterCube;
