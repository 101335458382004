import { useEffect, useState } from 'react';
import BrochureCube from '../../Components/Media/BrochureCube';
import MediaNavButtons from '../../Components/Media/MediaNavButtons';
import TemplateTilePage from '../../Components/Shared/TemplateTilePage';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../../Components/Shared/FormatImageLink';

const Brochures = () => {
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const brochureBanner = generalImages.find((i) => i.publicId === 'G_Brochures_xomevm')?.imageLink;
  const axiosJWT = useAxiosJWT();
  const [elementsMapped, setElementsMapped] = useState([]);

  useEffect(() => {
    document.title = 'Brochures - National Public Seating';
  }, []);

  useEffect(() => {
    axiosJWT
      .get(`/content/getBrochures`)
      .then((res) => {
        setElementsMapped(
          res.data.brochures.map((i, index) => {
            return (
              <BrochureCube
                key={index}
                frameData={i}
              />
            );
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <TemplateTilePage
      banner={
        <img
          src={optimizeCloudinaryUrl(brochureBanner)}
          alt='Brochures Banner'
        ></img>
      }
      elements={elementsMapped}
      navigateBar={'Brochures'}
      middleElement={
        <div>
          <MediaNavButtons current={'Brochures'} />
        </div>
      }
    />
  );
};
export default Brochures;
