import { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import MobileMenuItem from './MobileMenuItem';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../Shared/FormatImageLink';

const MobileDropMenu = ({
  menuData,
  globalMenuData,
  setGlobalMenuData,
  categoryTitle,
  secondarySublinks,
  showDropdownMenu,
  setShowDropdownMenu,
  fixList,
  setFixList,
  needsOverlay,
  productsList,
}) => {
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const [newMenuData, setMenuData] = useState(menuData);
  const close = () => {
    setShowDropdownMenu(false);
  };
  const categorizeTabs = () => {
    const newArray = [];
    newArray.push({
      title: "WHAT'S NEW",
      link: 'whats-new',
    });
    for (let i = 0; i < newMenuData.length; i++) {
      let found = false;
      for (let j = 0; j < newArray.length; j++) {
        if (newMenuData[i].tab.toLowerCase() === newArray[j].title.toLowerCase()) {
          newArray[j].sublinks.push(newMenuData[i]);
          found = true;
          break;
        }
      }
      if (!found) {
        newArray.push({
          title: newMenuData[i].tab.toUpperCase(),
          sublinks: [newMenuData[i]],
        });
      }
    }
    const order = ["WHAT'S NEW", 'PRODUCTS', 'ENVIRONMENT', 'MEDIA', 'DEALER', 'ABOUT'];
    const orderedArray = order.map((tabName) => {
      const currentTab = newArray.find((item) => item.title === tabName);
      if (currentTab.title === 'ENVIRONMENT') {
        currentTab.title = 'ENVIRONMENTS';
      } else if (currentTab.title === 'MEDIA') {
        currentTab.title = 'MEDIA CENTER';
      } else if (currentTab.title === 'DEALER') {
        currentTab.title = 'DEALER PORTAL';
      }
      return currentTab;
    });
    setMenuData(orderedArray);
    setFixList(false);
    return;
  };
  const addViewAllLinks = (arr) => {
    for (let i = 0; i < arr.length; i++) {
      let needsViewAll = false;
      if (arr[i].sublinks) {
        //check if children have grandchildren
        addViewAllLinks(arr[i].sublinks);
        //look for grandchildren
        for (let j = 0; j < arr[i].sublinks.length; j++) {
          if (arr[i].sublinks[j].sublinks) {
            needsViewAll = true;
          }
        }
      }
      if (arr[i].secondarySublinks) {
        //check if children have grandchildren
        addViewAllLinks(arr[i].secondarySublinks);
        //look for grandchildren
        for (let j = 0; j < arr[i].secondarySublinks.length; j++) {
          if (arr[i].secondarySublinks[j].sublinks) {
            needsViewAll = true;
          }
        }
      }
      if (
        needsViewAll &&
        arr[i].title === 'DEALER PORTAL' &&
        arr[i].sublinks[arr[i].sublinks.length - 1].title !== 'Go to Dealer Portal'
      ) {
        arr[i].sublinks.push({
          title: 'Go to Dealer Portal',
          link: 'dealer-portal',
          special: 'special',
        });
      } else if (needsViewAll && arr[i].title === 'PRODUCTS') {
        arr[i].secondarySublinks.push({
          title: `View All Products`,
          link: `categories`,
          special: 'special',
        });
      } else if (needsViewAll && arr[i].tab === 'Products') {
        arr[i].sublinks.push({
          title: `View All ${arr[i].title}`,
          link: `categories/${arr[i].title}`,
          special: 'special',
        });
      }
    }
    return;
  };

  const editNestedArray = (nestedArray) => {
    return nestedArray.map((item) => {
      if (Array.isArray(item.subCats)) {
        return {
          tab: 'Products',
          title: item.catName,
          sublinks: editNestedArray(item.subCats),
        };
      } else {
        return {
          tab: 'Products',
          title: item.catName,
          sublinks: item.subCats,
        };
      }
    });
  };

  const insertProducts = () => {
    const newProductsList = editNestedArray(productsList);
    const holderArray = newMenuData;
    for (let i = 0; i < holderArray.length; i++) {
      if (holderArray[i].title === 'PRODUCTS') {
        holderArray[i].secondarySublinks = newProductsList;
      } else if (holderArray[i].title === 'MEDIA CENTER') {
        holderArray[i].secondarySublinks = [
          {
            title: 'View By Product',
            sublinks: [],
          },
        ];
        newProductsList.forEach((product) => {
          holderArray[i].secondarySublinks[0].sublinks.push({
            title: product.title,
            link: `media?Category=${product.title}`,
          });
        });
        holderArray[i].secondarySublinks[0].sublinks.push({
          title: 'View All Products',
          link: `media?type=Images&types=%5B%5D&Category=`,
          special: 'special',
        });
      }
    }
    setGlobalMenuData(globalMenuData, (globalMenuData[0].sublinks = holderArray));
    addViewAllLinks(holderArray);
    return;
  };
  function findCategory(menuItem) {
    const trail = [];
    var found = false;
    function recurse(categoryAry) {
      for (let i = 0; i < categoryAry?.length; i++) {
        trail.push(categoryAry[i]);
        // Found the category!
        if (categoryAry[i].title === menuItem.title) {
          found = true;
          break;
          // Did not match...
        } else {
          // Are there sub-categories? YES
          if (categoryAry[i].hasOwnProperty('sublinks')) {
            recurse(categoryAry[i].sublinks);
            if (found) {
              break;
            }
          }
          if (categoryAry[i].hasOwnProperty('secondarySublinks')) {
            recurse(categoryAry[i].secondarySublinks);
            if (found) {
              break;
            }
          }
        }
        trail.pop();
      }
    }
    recurse(globalMenuData);
    return trail;
  }

  useEffect(() => {
    if (fixList) {
      categorizeTabs();
    }
  }, [fixList]);

  useEffect(() => {
    if (productsList && !fixList) {
      insertProducts();
    }
  }, [newMenuData]);

  return (
    <div className={`dropdown-menu-container`}>
      <div className={`dropdown-menu ${showDropdownMenu ? 'menuOpen' : 'menuClose'}`}>
        <AiOutlineCloseCircle
          onClick={close}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              close();
            }
          }}
          className='close-button'
        />
        <Link
          to={'/'}
          onClick={close}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              close();
            }
          }}
          className='dropdown-logo-div'
        >
          <img
            src={optimizeCloudinaryUrl(
              generalImages.find((i) => i.publicId === 'NPS_White_logo_lyi0cg')?.imageLink
            )}
            alt='National Public Seating'
          ></img>
        </Link>
        <div className='header-nav-links'>
          {categoryTitle ?
            <MobileMenuItem
              menuItem={categoryTitle}
              setShowDropdownMenu={setShowDropdownMenu}
              globalMenuData={globalMenuData}
              trail={findCategory(categoryTitle)}
            ></MobileMenuItem>
          : ''}
          {newMenuData[0].title.toLowerCase() === 'quick ship' ?
            <div>
              <MobileMenuItem
                menuItem={newMenuData[1]}
                setShowDropdownMenu={setShowDropdownMenu}
                globalMenuData={globalMenuData}
                key={newMenuData.indexOf(newMenuData[1])}
                headerLink={false}
                special={'NewProducts'}
              ></MobileMenuItem>
              <MobileMenuItem
                menuItem={newMenuData[0]}
                setShowDropdownMenu={setShowDropdownMenu}
                globalMenuData={globalMenuData}
                key={newMenuData.indexOf(newMenuData[0])}
                headerLink={false}
                special={'QuickShip'}
              ></MobileMenuItem>
            </div>
          : newMenuData.map((menuItem) => {
              return (
                <MobileMenuItem
                  menuItem={menuItem}
                  setShowDropdownMenu={setShowDropdownMenu}
                  special={menuItem.special}
                  globalMenuData={globalMenuData}
                  key={newMenuData.indexOf(menuItem)}
                  headerLink={false}
                ></MobileMenuItem>
              );
            })
          }
          {secondarySublinks ?
            <div className='header-nav-secondary-links'>
              {secondarySublinks.map((sublink) => {
                return (
                  <MobileMenuItem
                    menuItem={sublink}
                    setShowDropdownMenu={setShowDropdownMenu}
                    special={sublink.special}
                    globalMenuData={globalMenuData}
                    key={newMenuData.indexOf(sublink)}
                    headerLink={false}
                  ></MobileMenuItem>
                );
              })}
            </div>
          : ''}
        </div>
      </div>
      <div className={needsOverlay ? 'dropdown-overlay' : ''}></div>
    </div>
  );
};

export default MobileDropMenu;
