import { useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../Components/Shared/Loading';
import TemplateTilePage from '../../Components/Shared/TemplateTilePage';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../../Components/Shared/FormatImageLink';
const QuickShip = () => {
  const { generalImages } = useGeneralImages();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  // const products = [
  //   {
  //     catName: "Max Seating Folding Tables",
  //     modelNums: [
  //       {
  //         category: "Max Seating Folding Tables",
  //         title: "MSFT1872PWEBPT",
  //         image:
  //           "v1683550157/Product Assets/Tables/MSFTC Series/MSFTC1872MY_1_ilotev.jpg",
  //         configOptions: {
  //           LegType: "Standard",
  //           Width: 18,
  //           Length: 72,
  //           Core: "Particle Board",
  //           Edge: "T-Mold",
  //         },
  //       },
  //       {
  //         category: "Max Seating Folding Tables",
  //         title: "MSFT1896PWEBPT",
  //         image:
  //           "v1683550157/Product Assets/Tables/MSFTC Series/MSFTC1872MY_1_ilotev.jpg",
  //         configOptions: {
  //           LegType: "Standard",
  //           Width: 18,
  //           Length: 96,
  //           Core: "Particle Board",
  //           Edge: "T-Mold",
  //         },
  //       },
  //     ],
  //   },
  //   {
  //     catName: "Collaborator Tables",
  //     modelNums: [
  //       {
  //         category: "Collaborator Tables",
  //         title: "CLT3072D2WB",
  //         image:
  //           "v1683571893/Product Assets/Tables/CLT Series/CLTB2BB_a0sfhg.jpg",
  //         configOptions: { Width: 30, Length: 72, Tabletop: "Whiteboard" },
  //       },
  //       {
  //         category: "Collaborator Tables",
  //         title: "CLT3060D2BB",
  //         image:
  //           "v1683571893/Product Assets/Tables/CLT Series/CLTB2BB_a0sfhg.jpg",
  //         configOptions: { Width: 30, Length: 60, Tabletop: "ButcherBlock" },
  //       },
  //       {
  //         category: "Collaborator Tables",
  //         title: "CLT3072D2BB",
  //         image:
  //           "v1683571893/Product Assets/Tables/CLT Series/CLTB2BB_a0sfhg.jpg",
  //         configOptions: { Width: 30, Length: 72, Tabletop: "ButcherBlock" },
  //       },
  //       {
  //         category: "Collaborator Tables",
  //         title: "CLT3072B2WB",
  //         image:
  //           "v1683571893/Product Assets/Tables/CLT Series/CLTB2BB_a0sfhg.jpg",
  //         configOptions: { Width: 30, Length: 72, Tabletop: "Whiteboard" },
  //       },
  //       {
  //         category: "Collaborator Tables",
  //         title: "CLT3060B2BB",
  //         image:
  //           "v1683571893/Product Assets/Tables/CLT Series/CLTB2BB_a0sfhg.jpg",
  //         configOptions: { Width: 30, Length: 60, Tabletop: "ButcherBlock" },
  //       },
  //       {
  //         category: "Collaborator Tables",
  //         title: "CLT3072B2BB",
  //         image:
  //           "v1683571893/Product Assets/Tables/CLT Series/CLTB2BB_a0sfhg.jpg",
  //         configOptions: {
  //           Width: '30"',
  //           Length: '72"',
  //           Tabletop: "Butcherblock",
  //         },
  //       },
  //     ],
  //   },
  // ];

  const { seriesID } = useParams();
  const navigate = useNavigate();
  const [catData, setCatData] = useState();
  const quickShipBanner = generalImages.find(
    (i) => i.publicId === 'QuickShip_Banner_dhkmtf'
  )?.imageLink;

  useEffect(() => {
    document.title = `${seriesID ? seriesID : ''} Quick Ship- National Public Seating`;
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (seriesID) {
      setCatData();
      axiosJWT
        .post('/products/getQuickShip', { seriesID: seriesID })
        .then((response) => {
          setCatData(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      axiosJWT
        .get('/products/getQuickShip')
        .then((response) => {
          setCatData(response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [seriesID]);
  const axiosJWT = useAxiosJWT();

  function clickHandler(item) {
    //navigate to the products page with all the params prefilled for that model#
    if (seriesID) {
      navigate({
        pathname: `/products/${encodeURIComponent(item.category)}`, //this is coming out as the model number, needs to be the parent category
        search: `?${createSearchParams(item.configOptions)}`,
      });
    } else {
      navigate(`/quickship/${item.title}`);
    }
  }

  return (
    <div>
      {catData ?
        <TemplateTilePage
          list={catData}
          clickHandler={clickHandler}
          banner={
            <img
              src={optimizeCloudinaryUrl(quickShipBanner)}
              alt='QuickShip Products Banner'
            ></img>
          }
          qsNavigate={seriesID}
        />
      : <Loading />}
    </div>
  );
};

export default QuickShip;
