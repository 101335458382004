import { AdvancedImage } from '@cloudinary/react';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { useEffect, useState } from 'react';
import { GoDownload } from 'react-icons/go';
import { IoClose } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import useUserInfo from '../../hooks/useUserInfo';
import ImageSlider from '../Shared/ImageSlider';
import ShowcaseItem from './ShowcaseItem';
import useGeneralImages from '../../hooks/useGeneralImages';
import FormatImageLink from '../Shared/FormatImageLink';

const EnvironmentPopUp = ({ data, filter, setShow }) => {
  const { auth } = useAuth();
  const { generalImages } = useGeneralImages();
  const { displayLoginBox, displayBoolean } = useUserInfo();
  const { decodeAndFormat } = FormatImageLink();
  const [downloadClicked, setDownloadClicked] = useState(false);
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const download = generalImages.find((i) => i.publicId === 'download_pgzd5n')?.imageLink;
  const comingSoon = generalImages.find(
    (i) => i.publicId === 'image-coming-soon_lzhzdk'
  )?.imageLink;

  var mobileEnvirArray = [];

  data?.items?.map((i) => {
    return mobileEnvirArray.push(
      <ShowcaseItem
        item={i}
        key={i.sku}
      />
    );
  });

  useEffect(() => {
    if (!displayBoolean && auth?.rep && downloadClicked) {
      window.open(data.image);
    }
  }, [displayBoolean]);

  return (
    <div className={`environ-pop ${mobileScreen ? 'mobile' : ''}`}>
      <div className='popup-header'>
        <h2>
          {filter !== 'all' ?
            data.category.find((i) => i.toLowerCase() === filter.toLowerCase())
          : data.category[0]}
        </h2>
        <div className='underline'></div>
      </div>
      <div className='flexbox'>
        <div className='env-main-img'>
          {data?.image ?
            <AdvancedImage
              className='big-image'
              cldImg={decodeAndFormat(data?.image)?.resize(fill().width(772).height(660))}
            />
          : <AdvancedImage
              className='coming-soon'
              cldImg={decodeAndFormat(comingSoon)}
            />
          }

          {mobileScreen && (
            <IoClose
              className='close-button'
              onClick={() => {
                setShow('');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setShow('');
                }
              }}
            />
          )}
          {data?.image && (
            <GoDownload
              className='download-env'
              onClick={() => {
                setDownloadClicked(true);
                if (auth?.rep) {
                  window.open(data.image);
                } else {
                  displayLoginBox();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setDownloadClicked(true);
                  if (auth?.rep) {
                    window.open(`${data.image}`);
                  } else {
                    displayLoginBox();
                  }
                }
              }}
              src={download}
            />
          )}
        </div>
        <div className='right-div'>
          <div className='showcase-title'>
            <h3>Showcase |</h3>
            <h4>Click on products to learn more</h4>
          </div>
          <div className='item-div'>
            {!mobileScreen ?
              mobileEnvirArray
            : <ImageSlider
                images={mobileEnvirArray}
                numOfDisplay={4}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnvironmentPopUp;
