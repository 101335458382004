import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { SlArrowRight } from 'react-icons/sl';
import '../CSS/LeadTimes.css';
import TemplateTilePage from '../Components/Shared/TemplateTilePage';
import { createSearchParams, useNavigate } from 'react-router-dom';
import Paginator from '../Components/Media/Paginator';
import Loading from '../Components/Shared/Loading';
import { FaChevronRight } from 'react-icons/fa';
import FormatImageLink from '../Components/Shared/FormatImageLink';

const LeadTimes = () => {
  const apiAddress = import.meta.env.VITE_SERVER_API_ADDRESS;
  const navigate = useNavigate();
  const { optimizeCloudinaryUrl } = FormatImageLink();
  const [leadTimes, setLeadTimes] = useState();
  const [selected, setSelected] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [hover, setHover] = useState();
  const [categories, setCategories] = useState();
  const [displayData, setDisplayData] = useState();
  const [hierarchyList, setHierarchy] = useState([]);
  const [dataAmount, setDataAmount] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [perPage, setPerPage] = useState(16);

  function getData() {
    setIsLoading(true);
    Axios.get(`${apiAddress}/content/getLeadTimes`).then((response) => {
      setLeadTimes(response.data);
      setSelected(response.data[0]);
      setIsLoading(false);
    });
  }

  function getCategories() {
    Axios.post(`${apiAddress}/products/getCategoriesForLT`, {
      leadTime: selected.leadtime,
    })
      .then((response) => {
        setCategories(response.data);
        setDisplayData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching total data amount:', error);
      });
  }

  function getItemsByLeadTime(item) {
    Axios.post(`${apiAddress}/products/getItemsByLeadTime`, {
      leadTime: selected.leadtime,
      series: item.title,
      page: pageNumber,
      perPage: perPage,
    }).then((response) => {
      setDisplayData(response.data);
    });
  }

  function getTotalDataAmount(item) {
    Axios.post(`${apiAddress}/products/getLeadTimeItemsCount`, {
      leadTime: selected.leadtime,
      series: item.title,
    })
      .then((response) => {
        setDataAmount(response.data);
      })
      .catch((error) => {
        console.error('Error fetching total data amount:', error);
      });
  }

  function clickHandler(item) {
    if (item?.title === 'Categories') {
      setDisplayData(categories);
      setDataAmount();
      setPageNumber(0);
      setHierarchy([]);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (item?.subcategories?.length > 0) {
      setDisplayData(item?.subcategories);
      setDataAmount();
      setPageNumber(0);
      setHierarchy((prev) => {
        const index = prev.findIndex((existingItem) => existingItem.title === item.title);
        if (index !== -1) {
          return prev.slice(0, index + 1);
        }

        return prev?.length <= 0 ? [...prev, { title: 'Categories' }, item] : [...prev, item];
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else if (item?.subcategories) {
      getItemsByLeadTime(item);
      getTotalDataAmount(item);
      setHierarchy((prev) => {
        const index = prev.findIndex((existingItem) => existingItem.title === item.title);
        if (index !== -1) {
          return prev.slice(0, index + 1);
        }
        return [...prev, item];
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate({
        pathname: `/products/${encodeURIComponent(item.series)}`,
        search: `?${createSearchParams(item.configOptions)}`,
      });
    }
  }

  useEffect(() => {
    document.title = `Product by Lead Time - National Public Seating`;
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (selected) {
      getCategories();
      setHierarchy([]);
      setDataAmount();
      setPageNumber(0);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [selected]);

  useEffect(() => {
    if (selected && pageNumber > 0) {
      getItemsByLeadTime(hierarchyList[hierarchyList?.length - 1]);
    }
  }, [pageNumber, perPage]);

  useEffect(() => {
    if (pageNumber + 1 > Math.ceil(dataAmount / perPage)) {
      setPageNumber(Math.ceil(dataAmount / perPage) - 1);
    }
  }, [perPage, pageNumber]);

  return (
    <div>
      <div className='basic-width'>
        <div className='mediaDownloads'>
          <div className='navigateBar'>
            <span
              aria-label='categories'
              role='button'
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  navigate('/categories');
                }
              }}
              onClick={() => navigate('/categories')}
            >
              Products
            </span>
            <SlArrowRight />
            <div className='current'>Products by Lead Time</div>
          </div>
        </div>
        <div className='explore-text new-products-title'>Product By Lead Time</div>

        <div className='lead-time-titles'>
          {leadTimes?.map((i, index) => (
            <div
              aria-label={i.title}
              role='button'
              tabIndex={0}
              onClick={() => setSelected(i)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setSelected(i);
                }
              }}
              className={selected === i ? 'selected-lead-time' : 'lead-time'}
              key={index}
            >
              {i.title}
            </div>
          ))}
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className='leadtime-overstock-button-div'
          >
            {hover ?
              <div className='hover-details-div'>
                Check out our limited Stock and non-standard items, delivered in 1-2 weeks.
              </div>
            : ''}
            <button
              onClick={() => navigate('/overstock-products')}
              className='basic-button-design white-bordered-button'
            >
              Overstock
              <span>
                <FaChevronRight />
              </span>
            </button>
          </div>
        </div>
      </div>
      {isLoading ?
        <Loading />
      : <>
          <TemplateTilePage
            list={displayData}
            bannerClass={'basic-width'}
            clickHandler={clickHandler}
            banner={
              <>
                <div className='lead-time-banner'>
                  <img src={optimizeCloudinaryUrl(`${selected?.link}`)}></img>
                  <div className='leadtime-description-box'>
                    <div className='bullet-separated'>
                      <span>{selected?.title}</span>
                      <span>{selected?.leadtime} lead time</span>
                    </div>
                    <div className='leadtime-description'>{selected?.description}</div>
                  </div>
                </div>
                {hierarchyList?.length > 0 ?
                  <div className='mediaDownloads'>
                    <div className='navigateBar'>
                      {hierarchyList?.map((level, index) =>
                        index === hierarchyList?.length - 1 ?
                          <React.Fragment key={index}>
                            <div
                              role='button'
                              aria-label={level?.title}
                              tabIndex={0}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  clickHandler(level);
                                }
                              }}
                              onClick={() => clickHandler(level)}
                            >
                              {level?.title}
                            </div>
                          </React.Fragment>
                        : <React.Fragment key={index}>
                            <span
                              role='button'
                              aria-label={level?.title}
                              tabIndex={0}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  clickHandler(level);
                                }
                              }}
                              onClick={() => clickHandler(level)}
                            >
                              {level?.title}
                            </span>
                            <SlArrowRight />
                          </React.Fragment>
                      )}
                    </div>
                  </div>
                : ''}
              </>
            }
          />
          {dataAmount && Math.ceil(dataAmount / perPage) > 1 ?
            <div className='pages inner-padding'>
              <Paginator
                currentPage={pageNumber}
                totalPages={Math.ceil(dataAmount / perPage)}
                setCurrentPage={setPageNumber}
                setShow={setPerPage}
                show={perPage}
              />
            </div>
          : ''}
        </>
      }
    </div>
  );
};

export default LeadTimes;
