import { IoCloseCircleOutline } from 'react-icons/io5';
import ReactPlayer from 'react-player';
import '../../CSS/VideoPlayer.css';
import BasicButton from '../NPSportal/basicButton';
const VideoPlayer = ({
  videoSrc,
  setShow,
  autoPlay,
  actionButton = undefined,
  showCorner = false,
}) => {
  return (
    <div className='video-popup'>
      <div className={`video-player ${showCorner ? 'corner' : ''}`}>
        <IoCloseCircleOutline
          className='closeButton'
          onClick={() => {
            setShow(false);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setShow(false);
            }
          }}
        />
        <ReactPlayer
          url={videoSrc}
          // className="react-player"
          width={'80%'}
          height={'90%'}
          controls
          playing={true}
          muted={autoPlay ? true : undefined} //you can only do autoplay if it is muted
        />
        {actionButton && (
          <BasicButton
            text={actionButton.title}
            buttonStyle={{ height: '3rem', marginBottom: '1rem' }}
            type={'white-bordered-button'}
            onClick={actionButton.action}
          />
        )}
      </div>
      {!showCorner && <div className='modal'></div>}
    </div>
  );
};

export default VideoPlayer;

