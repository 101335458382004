import { Link, useNavigate } from 'react-router-dom';
import '../../CSS/EnvironmentsDrop.css';
import FormatImageLink from '../Shared/FormatImageLink';
const Environmentsdrop = ({ data }) => {
  const navigate = useNavigate();
  const { optimizeCloudinaryUrl } = FormatImageLink();

  return (
    <div className='env-div'>
      <div className='media-title-div'>
        <h2>Environments</h2>
        <Link to='/environments'>VIEW ALL</Link>
      </div>
      <div className='grid-container'>
        {data?.map((e) => (
          <div
            role='button'
            aria-label={e.title}
            tabIndex={0}
            key={e.title}
            className='env-tile'
            onClick={() => {
              navigate({
                pathname: `/environments`,
                search: `?filter=${e.title.toLowerCase()}`,
              });
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                navigate({
                  pathname: `/environments`,
                  search: `?filter=${e.title.toLowerCase()}`,
                });
              }
            }}
          >
            <img
              src={optimizeCloudinaryUrl(e.image)}
              alt={e.title}
            ></img>
            <h3>{e.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Environmentsdrop;
